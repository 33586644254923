import {
  white,
  black,
  blue,
  green,
  orange,
  red,
  purple,
  brown,
  gray,
  grey,
  stateErrorRaya,
  stateErrorSoftRaya,
  stateWarningRaya,
  stateInfoRaya,
  stateSuccessRaya,
  stateSuccessSoftRaya,
  dustyOrange,
  blueScan,
  brightCeruleaRaya,
  greyshadowSurface,
  greyshadowElevation,
  greyishSeparator,
  blueShadowBox,
  background,
  darkBackground,
  greyishContainer,
  divider,
  textPrimary,
  textSecondary,
  textDisable,
  neutralNr10,
  neutralNr20,
  neutralNr30,
  neutralNr50,
  neutralNr60,
  neutralNr80,
  primaryBpjs10,
  primaryBpjs50,
  primaryBpjs100,
  // secondaryBpjs10,
  // secondaryBpjs50,
  secondaryBpjs100,
  secondaryBpjs5
} from '../../common/colors';

export default {
  common: {
    white,
    black,
    blue,
    green,
    orange,
    red,
    purple,
    brown,
    gray,
    grey,
    dustyOrange,
    blueScan,
    brightCeruleaRaya,
    blueShadowBox,
    greyishContainer,
    greyishSeparator,
    divider,
    neutralNr20,
    neutralNr30,
    neutralNr50,
    neutralNr60,
    neutralNr80
  },
  primary: {
    light: primaryBpjs10,
    main: primaryBpjs50,
    dark: primaryBpjs100,
    contrastText: white
  },
  secondary: {
    light: secondaryBpjs5,
    main: primaryBpjs50,
    dark: secondaryBpjs100,
    contrastText: white
  },
  error: {
    light: stateErrorSoftRaya,
    main: stateErrorRaya,
    dark: '',
    contrastText: white
  },
  warning: {
    light: '',
    main: stateWarningRaya,
    dark: '',
    contrastText: white
  },
  info: {
    light: '',
    main: stateInfoRaya,
    dark: '',
    contrastText: white
  },
  success: {
    light: stateSuccessSoftRaya,
    main: stateSuccessRaya,
    dark: '',
    contrastText: white
  },
  background: {
    default: background,
    dark: darkBackground,
    paper: neutralNr10
  },
  text: {
    primary: textPrimary,
    secondary: textSecondary,
    disabled: textDisable,
    error: stateErrorRaya
  },
  surface: {
    greyshadowSurface,
    greyshadowElevation
  }
};
