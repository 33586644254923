export const white = '#FFFFFF';
export const black = '#000000';
export const blue = '#0767DB';
export const green = '#2BAA3E';
export const orange = '#F58A23';
export const red = '#ED4740';
export const purple = '#8e44ad';
export const brown = '#4a4a4a';
export const gray = '#7f8c8d';
export const grey = {
  50: '#FAFAFA',
  100: '#F5F5F5',
  200: '#EEEEEE',
  300: '#E0E0E0',
  400: '#BDBDBD',
  500: '#9E9E9E',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121'
};

/*
 **
 ** PALETTE COLOR CUSTOMIZE
 **
 */
export const stateErrorRaya = '#D70C24';
export const stateErrorSoftRaya = '#FBE7E9';
export const stateWarningRaya = '#FFB822';
export const stateInfoRaya = '#1BB0DF';
export const stateSuccessRaya = '#38BAA7';
export const stateSuccessSoftRaya = '#EBF8F6';
export const dustyOrange = '#f58a23';
export const blueScan = '#28B0EA';
export const lightBlue = '#209bbd';
export const brightCeruleaRaya = '#E8F7FC';
export const greyshadowSurface = 'rgba(96, 97, 112, 0.16)';
export const greyshadowElevation = 'rgba(40, 41, 61, 0.04)';
export const greyishSeparator = '#334a4a';
export const greyBorder = '#F3F3F3';
export const blueShadowBox = '#344d64';
export const background = '#f8fafc';
export const darkBackground = '#172B4D';
export const greyishContainer = '#f7f7f7';
export const divider = '#DFE3E8';

/*
 **
 ** PALETTE COLOR TEXT
 **
 */
export const textPrimary = '#03213E';
export const textSecondary = '#66788A';
export const textDisable = '#A6B1BB';

/*
 **
 ** PALETTE COLOR NEUTRAL
 **
 */
export const neutralNr10 = '#ffffff';
export const neutralNr20 = '#F8F9FA';
export const neutralNr30 = '#EBEDF0';
export const neutralNr40 = '#CDD3D8';
export const neutralNr50 = '#B3BCC5'; //Baseline
export const neutralNr60 = '#9AA6B2';
export const neutralNr70 = '#81909F';
export const neutralNr80 = '#66788A';
export const neutralNr90 = '#1C3751';
export const neutralNr100 = '#03213E';

/*
 **
 ** PALETTE COLOR FOR GENERAL
 **
 */
export const primaryPr10 = '#E6EEF5';
export const primaryPr20 = '#9CBAD7';
export const primaryPr30 = '#5187B8';
export const primaryPr40 = '#2064A4';
export const primaryPr50 = '#07539A'; //Baseline
export const primaryPr60 = '#064B8B';
export const primaryPr70 = '#06427B';
export const primaryPr80 = '#053A6C';
export const primaryPr90 = '#04325C';
export const primaryPr100 = '#042A4D';

export const secondarySc10 = '#FEF5EE';
export const secondarySc20 = '#F8B893';
export const secondarySc30 = '#F59C67';
export const secondarySc40 = '#F27F3C';
export const secondarySc50 = '#F07126'; //Baseline
export const secondarySc60 = '#D86622';
export const secondarySc70 = '#C05A1E';
export const secondarySc80 = '#A84F1B';
export const secondarySc90 = '#904417';
export const secondarySc100 = '#783913';

/*
 **
 ** PALETTE COLOR FOR BPJS DANA SIAGA
 **
 */
export const primaryBpjs5 = '#DBF1E2';
export const primaryBpjs10 = '#B7E4C4';
export const primaryBpjs20 = '#A5DDB6';
export const primaryBpjs30 = '#88D29D';
export const primaryBpjs40 = '#6AC684';
export const primaryBpjs50 = '#4CBB6C'; //Baseline
export const primaryBpjs60 = '#3F9C5A';
export const primaryBpjs70 = '#337D48';
export const primaryBpjs80 = '#265E36';
export const primaryBpjs90 = '#193E24';
export const primaryBpjs100 = '#0F2516';

export const secondaryBpjs5 = '#FEF2CE';
export const secondaryBpjs10 = '#FDEAAE';
export const secondaryBpjs20 = '#FDDF85';
export const secondaryBpjs30 = '#FCD45C';
export const secondaryBpjs40 = '#FBCA34';
export const secondaryBpjs50 = '#FABF0B'; //Baseline
export const secondaryBpjs60 = '#D09F09';
export const secondaryBpjs70 = '#A77F07';
export const secondaryBpjs80 = '#7D6006';
export const secondaryBpjs90 = '#534004';
export const secondaryBpjs100 = '#322602';