import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/Utility';

const initialState = {
  forgotPinInputOtpRequestBody: {},
  isNewPinAlreadyUsed: false
};

const setForgotPinInputOtpBodyRequest = (state, action) => {
  return updateObject(state, {
    forgotPinInputOtpRequestBody: action.forgotPinInputOtpRequestBody
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FORGOT_PIN_INPUT_OTP_REQUEST_BODY:
      return setForgotPinInputOtpBodyRequest(state, action);
    case actionTypes.SET_FORGOT_PIN_NEW_PIN_ALREADY_USED:
      return setForgotPinNewPinAlreadyUsed(state, action);
    default:
      return state;
  }
};

const setForgotPinNewPinAlreadyUsed = (state, action) => {
  return updateObject(state, {
    isNewPinAlreadyUsed: action.isNewPinAlreadyUsed
  });
};

export default reducer;
