import React, { Suspense } from 'react';
import { Router, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';
import Routes from './Routes';
import { connect } from 'react-redux';
import * as actions from './store/actions';
import theme from './theme';
import {
  CircularProgress,
  makeStyles,
  Backdrop,
  Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { setCookie } from 'helpers/saveToCookies';
import {
  USER_LOGIN_DATA,
  PARTNER_ID,
  DEVICE_INFORMATION
} from 'common/constants';
import CryptoJS from 'crypto-js';
import { getDecryptJwtToken, getDeviceInformation } from 'helpers/Utility';

const browserHistory = createBrowserHistory();

const useStyles = makeStyles(() => ({
  content: {
    backgroundColor: 'transparent',
    maxWidth: '500px',
    margin: '0 auto'
  },
  backdrop: {
    zIndex: 1305,
    color: '#fff'
  },
  snackbarAlert: {
    width: '470px'
  }
}));

const App = props => {
  const { appLoad, setParameterAccess, setDeviceInformation } = props;

  const [isOnline, setIsOnline] = React.useState(navigator.onLine);

  const classes = useStyles();

  React.useLayoutEffect(() => {
    const decodedToken = getDecryptJwtToken();
    const deviceInformation = getDeviceInformation();
    if (decodedToken) {
      const encryptDecodedToken = CryptoJS.AES.encrypt(
        JSON.stringify(decodedToken),
        process.env.REACT_APP_SECRET_KEY
      ).toString();
      setCookie(USER_LOGIN_DATA, encryptDecodedToken, 10);
      setCookie(PARTNER_ID, decodedToken.partnerId, 10);
      setParameterAccess(decodedToken);
    }
    if (deviceInformation) {
      setCookie(DEVICE_INFORMATION, JSON.stringify(deviceInformation), 10);
      setDeviceInformation(deviceInformation);
    }

    if (process.env.REACT_APP_INSPECT_ELEMENT === 'true') {
      // Disable right-click
      document.addEventListener('contextmenu', e => e.preventDefault());

      // Disable shortcut for inspect
      document.onkeydown = e => {
        if (
          e.keyCode === 123 ||
          ctrlShiftKey(e, 'I') ||
          ctrlShiftKey(e, 'J') ||
          ctrlShiftKey(e, 'C') ||
          (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
        )
          return false;
      };
    }
  }, [setParameterAccess]);

  React.useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, []);

  const ctrlShiftKey = (e, keyCode) => {
    return (
      (e.ctrlKey || e.metaKey) &&
      e.shiftKey &&
      e.keyCode === keyCode.charCodeAt(0)
    );
  };

  const loading = (
    <Backdrop className={classes.backdrop} open={appLoad}>
      <center>
        <CircularProgress />
      </center>
    </Backdrop>
  );

  return (
    <>
      <div className={classes.content}>
        <ThemeProvider theme={theme}>
          {loading}
          <Router history={browserHistory}>
            <Suspense
              fallback={
                <span
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    margin: '-25px 0 0 -25px'
                  }}
                >
                  <CircularProgress />
                </span>
              }
            >
              <Routes />
              {props.error === 'system' ||
              props.error === 'connection' ||
              props.error === 'contact-cs' ||
              props.error === 'DIG_SIG_FAIL' ||
              props.error === 'DISBURSE_FAIL' ||
              props.error === 'DIG_SIG_FAIL7' ||
              props.error === 'number-not-exist' ||
              props.error === 'miss_match' ? (
                <Redirect to="/exception-handling" />
              ) : null}
              <Snackbar open={!isOnline}>
                <MuiAlert
                  className={classes.snackbarAlert}
                  elevation={6}
                  severity="error"
                  variant="filled"
                >
                  Tidak ada koneksi Internet
                </MuiAlert>
              </Snackbar>
            </Suspense>
          </Router>
        </ThemeProvider>
      </div>
    </>
  );
};

const mapDispatchStatesToProps = states => {
  return {
    phoneNo: states.auth.phoneNo,
    token: states.auth.oauthToken,
    appLoad: states.loanApply.loading,
    error: states.auth.error,
    applicationId: states.loanApply.applicationId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    appInquiry: auth => dispatch(actions.applicationInquiry(auth)),
    setParameterAccess: params => dispatch(actions.setParameterAccess(params)),
    setDeviceInformation: deviceInformation =>
      dispatch(actions.setDeviceInformation(deviceInformation))
  };
};

export default connect(mapDispatchStatesToProps, mapDispatchToProps)(App);
