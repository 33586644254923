import * as actionTypes from './actionTypes';
import axios from '../../lib/axios/axios';
import axiosGolang from 'lib/axios/axios-golang';
import { isObjectEmpty } from 'helpers/Utility';
import {
  USERS,
  USERSV2,
  CODE_REFERENCES,
  PROVINCE,
  COMPANY_INFO,
  VERIFY_PAYROLL,
  FIELD_OF_WORKS,
  FIELD_OF_BUSINESS,
  PARTNER_ID_GENERAL
} from '../../common/config';
import { setHomePath, setError, setIsLogin, setOauthToken } from './auth';
import {
  setTimer,
  startTimer,
  setCreditScoreResult,
  setOffering1,
  setOffering2,
  setSelectedOffering,
  setloanAmountSelected,
  getLoanAsync,
  stopTimer,
  ekycCheckingStatus
} from './disburse';
import { getJsonStringify } from '../../helpers/Utility';
import { statusPayment, lastPayment } from './payment';
import { getChannelContextCode } from '../../helpers/Utility';
import { sendEvent, sendException } from 'helpers/analytics';
import { SCHEME_CODE_KASBON } from 'common/constants';

export const loading = val => {
  return {
    type: actionTypes.APP_LOADING,
    loading: val
  };
};

let controller = new AbortController();

export const applicationInquiry = (auth, params) => {
  return (dispatch, getState) => {
    dispatch(loading(true));
    const url = USERS + '/' + 'custom/applicationInquiry?recordCount=1';

    axiosGolang
      .get(url, { headers: { Authorization: auth.token } })
      .then(res => {
        const appStatus = res.data.applicationStatus.cmCode;
        const ekycStatus = res.data.EkycStatus;
        dispatch(setApplicationStatus(appStatus));
        dispatch(setAppInqRes(res.data));
        res.data.ktpNum && dispatch(setEktp(res.data.ktpNum));
        res.data.approvalDetails &&
          dispatch(setApprovalDetails(res.data.approvalDetails));
        res.data.approvalDetails &&
          dispatch(setCustomerName(res.data.approvalDetails.nama));
        res.data.incompleteApplicationDetails &&
          dispatch(setIncompleteApp(res.data.incompleteApplicationDetails));
        dispatch(setSecondApply(res.data.isSecondApply));

        if (appStatus.includes('_SAVED')) {
          dispatch(setHomePath('/continue-apply'));
          dispatch(loading(false));
        }
        if (appStatus.includes('CR_SCORE')) {
          dispatch(setHomePath('/credit-scoring'));
          if (appStatus === 'CR_SCORE_SUB') {
            localStorage.setItem('setCS', 1);
            dispatch(
              setTimer({
                minutes: res.data.timeLeftForScoreCompletion,
                seconds: 0
              })
            );
            dispatch(startTimer());
            if (params && !params.isCounting) {
              dispatch(setCreditScoreResult(''));
              dispatch(startTimer());
            }
          }
          if (appStatus === 'CR_SCORE_APR') {
            localStorage.removeItem('operationalDate');
            localStorage.removeItem('setCS');
            localStorage.removeItem('setCSFromFormFill');
            const isKtpVerified =
              res.data.approvalDetails &&
              res.data.approvalDetails.isKTPVerified;
            const alternative =
              res.data.approvalDetails && res.data.approvalDetails.alternative;
            const plafond1 =
              res.data.approvalDetails && res.data.approvalDetails.plafond1;
            const plafond2 =
              res.data.approvalDetails && res.data.approvalDetails.plafond2;
            const tenure1 =
              res.data.approvalDetails && res.data.approvalDetails.tenor1;
            const tenure2 =
              res.data.approvalDetails && res.data.approvalDetails.tenor2;
            const installment1 =
              res.data.approvalDetails &&
              res.data.approvalDetails.oneMonthPayDetails
                .oneMonthPayForPlafond1;
            //handling array monthlyPayDetails jika kosong.
            const installment2 =
              res.data.approvalDetails &&
              res.data.approvalDetails.monthlyPayDetails &&
              res.data.approvalDetails.monthlyPayDetails.find(
                i => i.loanTenor === Number(tenure2)
              ) &&
              res.data.approvalDetails.monthlyPayDetails.find(
                i => i.loanTenor === Number(tenure2)
              ).monthlyPayment;
            const approvalAmount =
              res.data.approvalDetails &&
              res.data.approvalDetails.approvalAmount;
            //plafond1 is applied loan amount
            //plafond2 is an option/offering from och
            //if OCH return plafond2 that is greater than plafond1 then, the user will get a special offering
            if (
              plafond1 === 0 &&
              isKtpVerified === 'Y' &&
              params &&
              params.checking === true
            ) {
              dispatch(
                setSelectedOffering({
                  plafond: plafond2,
                  tenure: tenure2
                })
              );
              dispatch(setCreditScoreResult('user-declined'));
              dispatch(setTimer({ minutes: '', seconds: '' }));
              dispatch(stopTimer());
            } else if (alternative && isKtpVerified === 'Y') {
              dispatch(
                setOffering1({
                  plafond: plafond2,
                  tenure: tenure2,
                  installment: installment2
                })
              );
              dispatch(setCreditScoreResult('user-declined-alternatif'));
              dispatch(setTimer({ minutes: '', seconds: '' }));
              dispatch(stopTimer());
            } else if (
              (plafond1 > 0 && plafond2 > 0 && plafond2 > plafond1) ||
              alternative
            ) {
              dispatch(
                setOffering1({
                  plafond: plafond2,
                  tenure: tenure2,
                  installment: installment2
                })
              );
              dispatch(
                setOffering2({
                  plafond: plafond1,
                  tenure: tenure1,
                  installment: installment1
                })
              );
              dispatch(setCreditScoreResult('offering'));
              dispatch(setTimer({ minutes: '', seconds: '' }));
              dispatch(stopTimer());
            } else {
              if (plafond1 > 0) {
                dispatch(
                  setSelectedOffering({
                    plafond: plafond1,
                    tenure: tenure1
                  })
                );
              } else {
                dispatch(
                  setSelectedOffering({
                    plafond: plafond2,
                    tenure: tenure2
                  })
                );
              }
              dispatch(setCreditScoreResult('approved'));
              dispatch(setTimer({ minutes: '', seconds: '' }));
              dispatch(stopTimer());
            }

            if (approvalAmount > 0) {
              dispatch(setHomePath('/continue-disburse'));
            }
          }
          if (appStatus === 'CR_SCORE_REJ') {
            dispatch(
              setNextDate(res.data.rejectionDetails.nextEligibilityDate)
            );
            dispatch(setCreditScoreResult('rejected'));
            if (localStorage.getItem('documentDetails'))
              localStorage.removeItem('documentDetails');
            dispatch(setTimer({ minutes: '', seconds: '' }));
            dispatch(stopTimer());
          }

          dispatch(loading(false));
        }

        if (appStatus === 'DISB_ACC_CONF' && ekycStatus === 'P') {
          dispatch(
            ekycCheckingStatus({
              ...auth,
              applicationId: res.data.applicationId
            })
          );
        }

        if (appStatus === 'DISB_ACC_CONF' || appStatus === 'EKYC_COM') {
          dispatch(setHomePath('/continue-disburse'));
          dispatch(loading(false));
        }
        if (appStatus === 'DOCUMENT_SIGNED') {
          localStorage.removeItem('disburseLifetime');
          localStorage.removeItem('countHitButton');
          dispatch(setHomePath('/continue-disburse'));
          dispatch(loading(false));
        }
        if (appStatus === 'DISB_IN_PROCESS') {
          dispatch(setHomePath('/continue-disburse'));
          if (!getState().loanApply.disburseLifetime) {
            dispatch(
              setDisburseLifetime(
                res.data.disbursementDetails.disburseLifetime
                  ? res.data.disbursementDetails?.disburseLifetime + 3
                  : 0
              )
            );
          }
          dispatch(loading(false));
        }
        if (appStatus === 'DIG_SIGN_COM') {
          dispatch(setHomePath('/continue-disburse'));
          dispatch(
            setloanAmountSelected(
              res.data.incompleteApplicationDetails.approvalAmount
            )
          );
          dispatch(loading(false));
        }

        if (appStatus === 'LOAN_CREATED') {
          if (params?.checkingStatus) {
            params.checkingStatus();
            return;
          }
          localStorage.removeItem('disburseLifetime');
          localStorage.removeItem('countHitButton');
          dispatch(
            getLoanAsync(
              {
                phoneNo: auth.phoneNo,
                token: auth.token
              },
              {
                action: () => dispatch(setHomePath('/disburse-fin'))
              }
            )
          );
        }
        if (appStatus === 'LOAN_PAID') {
          dispatch(loading(false));
          if (
            localStorage.getItem('paymentStatus') ||
            localStorage.getItem('lastPayment')
          ) {
            dispatch(statusPayment(''));
            dispatch(lastPayment(''));
            localStorage.removeItem('paymentStatus');
            localStorage.removeItem('lastPayment');
          }
          localStorage.removeItem('freezeDateSuccess');
        }
        dispatch(setApplicationId(res.data.applicationId));
        if (appStatus === 'APP_EXPIRED') {
          dispatch(loading(false));
          res.data.previousLoanPaidAppId &&
            dispatch(setApplicationId(res.data.previousLoanPaidAppId));
        }
        if (appStatus === 'PAYROLL_REJ') {
          dispatch(setHomePath('/home'));
          dispatch(loading(false));
          dispatch(setNextDate(res.data.payrollrejdetails.nextEligibilityDate));
        }
        if (appStatus === 'PAYROLL_APP') {
          dispatch(setHomePath('/continue-apply'));
          dispatch(loading(false));
        }
        if (appStatus === 'APP_CREATED') {
          dispatch(setHomePath('/home'));
          dispatch(loading(false));
        }
        if (appStatus === 'USR_REJECT') {
          dispatch(setHomePath('/home'));
          dispatch(loading(false));
          dispatch(setNextDate(res.data.rejectionDetails.nextEligibilityDate));
        }
      })
      .then(() => {
        if (!params) return;
        params && params.action && params.action();
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 401) dispatch(setError(''));
          dispatch(loading(false));
        }

        if (!err.response) {
          dispatch(loading(false));
          dispatch(setError('connection'));
          dispatch(setOauthToken('', ''));
          dispatch(setIsLogin(false));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(loading(false));
          dispatch(setError('system'));
          dispatch(setOauthToken('', ''));
          dispatch(setIsLogin(false));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '8504') {
          dispatch(loading(false));
          dispatch(setApplicationStatus(''));
          params && params.empty();
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '14084') {
          dispatch(loading(false));
          dispatch(setError('contact-cs'));
          dispatch(setOauthToken('', ''));
          return;
        }

        dispatch(loading(false));
        dispatch(setError('system'));
        dispatch(setOauthToken('', ''));
        dispatch(setIsLogin(false));

        sendEvent({
          category: 'Unexpected Error Application Status',
          action: getJsonStringify(err.response),
          label: auth.phoneNo
        });
        sendException({
          desc: getJsonStringify(err.response),
          fatal: true
        });
      });
  };
};

const rehydratePersonInfo = applicantDetails => {
  return dispatch => {
    dispatch(
      setPersonInfo({
        email: applicantDetails.emailId,
        mobileNo: applicantDetails.mobileNo,
        name: applicantDetails.name,
        gender: applicantDetails.gender,
        placeOfBirth: applicantDetails.placeOfBirth,
        dateOfBirth: applicantDetails.dateOfBirth,
        nationalId: applicantDetails.nationalId,
        education: applicantDetails.lastEducation,
        haveCreditCard: !isObjectEmpty(applicantDetails.creditCardIssuer)
          ? true
          : false,
        creditCard: applicantDetails.creditCardIssuer,
        fullAddress: applicantDetails.addressLine1,
        rt: applicantDetails.addressLine2,
        rw: applicantDetails.addressLine3,
        kecamatan: applicantDetails.addressLine4,
        desa: applicantDetails.addressLine5,
        provinsi: applicantDetails.provinceDetails,
        kota: applicantDetails.city,
        postalCode: Number(applicantDetails.postalCode),
        domisiliStatus: applicantDetails.homeOwnershipStatus,
        homeOwnershipDuration: applicantDetails.homeOwnershipDuration,
        religion: applicantDetails.religion,
        haveSameAddress: applicantDetails.isAddressSameAsKTP,
        haveHousePhone: applicantDetails.homePhoneNumber ? true : false,
        phone: applicantDetails.homePhoneNumber,
        stayPeriod: applicantDetails.stayPeriod
      })
    );
  };
};

const rehydrateKtpAddressDetails = ktpAddressDetails => {
  return dispatch => {
    dispatch(
      setKtpAddressDetails({
        address: ktpAddressDetails.address || '',
        address2: ktpAddressDetails.address2 || '',
        address3: ktpAddressDetails.address3 || '',
        address4: ktpAddressDetails.address4 || '',
        agama: ktpAddressDetails.agama || '',
        bankCode: ktpAddressDetails.bankCode || '',
        birthDate: ktpAddressDetails.birthDate || '',
        cifNo: ktpAddressDetails.cifNo || '',
        fieldOfBusiness: ktpAddressDetails.businessFieldsCode || '',
        fieldOfWork: ktpAddressDetails.workingCode || '',
        idNo: ktpAddressDetails.idNo || '',
        idType: ktpAddressDetails.idType || '',
        nama: ktpAddressDetails.nama || '',
        placeOfBirth: ktpAddressDetails.placeOfBirth || '',
        province: ktpAddressDetails.province || '',
        sex: ktpAddressDetails.sex || '',
        zipCode: ktpAddressDetails.zipCode || '',
        nameWithoutTitle: ktpAddressDetails.nameWithoutTitle || ''
      })
    );
  };
};

/**
 *
 * @param {*} payrollDetails
 * @returns
 */
export const rehydrateOccupationInfo = payrollDetails => {
  const validateFieldOfBussinessOccupationInfo = () => {
    if (payrollDetails.businessFieldsCode) {
      if (payrollDetails.businessFieldsCode.length > 4) return '';
      else return payrollDetails.businessFieldsCode;
    } else if (payrollDetails.fieldOfBusiness) {
      return payrollDetails.fieldOfBusiness;
    } else return '';
  };

  const validateFieldOfSubBussinessOccupationInfo = () => {
    if (
      payrollDetails.subBusinessFieldsCode &&
      payrollDetails.subBusinessFieldsDesc
    ) {
      if (payrollDetails.businessFieldsCode.length === 4)
        return payrollDetails.subBusinessFieldsCode;
      else return '';
    } else if (payrollDetails.fieldOfSubBusiness) {
      return payrollDetails.fieldOfSubBusiness;
    } else return '';
  };

  const validateCompanyAddress = () => {
    if (payrollDetails.workAddress) {
      return payrollDetails.workAddress.substring(0, 256);
    } else if (payrollDetails.companyAddress) {
      return payrollDetails.companyAddress.substring(0, 256);
    } else return '';
  };

  return dispatch => {
    dispatch(
      setOccupationInfo({
        accountPurpose: payrollDetails.purposeOfOpenAcct || '',
        addressWL: payrollDetails.addressWL || '',
        birthDateWL: payrollDetails.birthDateWL || '',
        companyAddress: validateCompanyAddress(),
        companyName: payrollDetails.companyName || '',
        companyName2: payrollDetails.companyName2 || '',
        employeeStatus: payrollDetails.employeeStatus || '',
        endWorkingDate: payrollDetails.empEndDateWL || '',
        fieldOfBusiness: validateFieldOfBussinessOccupationInfo(),
        fieldOfBusinessDesc:
          payrollDetails.fieldOfBusinessDesc ||
          payrollDetails?.businessFieldsDesc ||
          '',
        fieldOfSubBusiness: validateFieldOfSubBussinessOccupationInfo(),
        fieldOfSubBusinessDesc:
          payrollDetails.fieldOfSubBusinessDesc ||
          payrollDetails?.subBusinessFieldsDesc ||
          '',
        fieldOfWork:
          payrollDetails.fieldOfWork || payrollDetails?.workingCode || '',
        fieldOfWorkDesc:
          payrollDetails.fieldOfWorkDesc || payrollDetails?.workingDesc || '',
        genderWL: payrollDetails.genderWL || '',
        income: payrollDetails.netIncomeWL || 0,
        nameWL: payrollDetails.nameWL || '',
        npwp: payrollDetails.npwp || '',
        outcome: payrollDetails.expenditureAmount || 0,
        payDay: new Date(payrollDetails.payrollDate).getDate() || 0,
        payrollAccount: payrollDetails.payrollAccount || '',
        payrollAccountBranch: payrollDetails.payrollAccountBranch || '',
        payrollDate: payrollDetails.payrollDate || '',
        placeOfBirthWL: payrollDetails.placeOfBirthWL || '',
        referralCode: payrollDetails.referralCode || '',
        savingAccOwnshpSts: payrollDetails.savingAccOwnshpSts || '',
        savingAmount: payrollDetails.savingAmount || 0,
        sourceOfIncome: payrollDetails.sourceOfIncome || '',
        startWorkingDate: payrollDetails.empStartDateWL || '',
        typeOfWork: payrollDetails.typeOfWork || ''
      })
    );
  };
};

const rehydrateFamilyInfo = contactInformation => {
  return dispatch => {
    dispatch(
      setFamilyInfo({
        emergencyNumber: String(contactInformation.contactNumber || ''),
        marriageStatus: contactInformation.maritalStatusReference,
        mothersMaidenName: contactInformation.motherName,
        numberOfDependents: String(contactInformation.noOfDependent),
        relationshipWithRelatives: contactInformation.relationshipReference,
        relativesName: contactInformation.contactName,
        spouseIdNo:
          contactInformation.spouseId !== '0'
            ? contactInformation.spouseId
            : contactInformation.partnerNikId
      })
    );
  };
};

export const fetchApplicationData = (auth, param) => {
  return dispatch => {
    dispatch(loading(true));
    const url =
      USERS +
      '/' +
      'custom/applications/' +
      (auth.prevAppId ? auth.prevAppId : auth.applicationId);
    const header = {
      headers: {
        Authorization: auth.token
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        dispatch(setApplicationdata(res.data));

        if (res.data.documentDetails) {
          const ektpImg = res.data.documentDetails.file.replace(
            /(?:\\[rn]|[\r\n]+)+/g,
            ''
          );
          dispatch(setEktpImg(String.raw`data:image/jpeg;base64,${ektpImg}`));
          dispatch(setDocumentDetails(res.data.documentDetails));
          dispatch(setEktp(res.data.documentDetails.docKey));
        }

        if (res.data.applicantDetails) {
          dispatch(setPersonInfo(res.data.applicantDetails));
        }

        if (res.data.respPayrollDetails) {
          dispatch(setRespPayrollDetails(res.data.respPayrollDetails));
          dispatch(setCustomerName(res.data.respPayrollDetails.nameWL));
          dispatch(
            setWhitelistAddress(
              res.data.respPayrollDetails.addressWL,
              res.data.applicantDetails
            )
          );
        }

        if (res.data.ktpAddressDetails) {
          dispatch(setKtpAddressDetails(res.data.ktpAddressDetails));
        }

        if (res.data.payrollDetails) {
          dispatch(setCustomerName(res.data.payrollDetails.nameWL));
          dispatch(
            setWhitelistAddress(
              res.data.payrollDetails.addressWL,
              res.data.applicantDetails
            )
          );
        }

        if (res.data.applicationDetails) {
          dispatch(
            setLoanAmount(res.data.applicationDetails.requestedLoanAmount)
          );
          dispatch(setTenure(res.data.applicationDetails.requestedTenor));
          dispatch(setApplicationDetails(res.data.applicationDetails));
          dispatch(setBankCode(res.data.applicationDetails.bankCode));

          if (
            res.data.applicationDetails.applicationStatus.cmCode === 'PER_SAVED'
          ) {
            dispatch(rehydratePersonInfo(res.data.applicantDetails));
            res.data.ktpAddressDetails &&
              dispatch(rehydrateKtpAddressDetails(res.data.ktpAddressDetails));
          } else if (
            res.data.applicationDetails.applicationStatus.cmCode === 'PAY_SAVED'
          ) {
            dispatch(rehydratePersonInfo(res.data.applicantDetails));
            res.data.ktpAddressDetails &&
              dispatch(rehydrateKtpAddressDetails(res.data.ktpAddressDetails));
            dispatch(rehydrateOccupationInfo(res.data.payrollDetails));
          } else if (
            res.data.applicationDetails.applicationStatus.cmCode ===
              'CON_SAVED' ||
            res.data.applicationDetails.applicationStatus.cmCode === 'LOAN_PAID'
          ) {
            dispatch(rehydratePersonInfo(res.data.applicantDetails));
            res.data.ktpAddressDetails &&
              dispatch(rehydrateKtpAddressDetails(res.data.ktpAddressDetails));
            dispatch(rehydrateOccupationInfo(res.data.payrollDetails));
            dispatch(rehydrateFamilyInfo(res.data.contactInformation));
          }
        }

        if (param?.hitAccountInquiry) {
          dispatch(
            accountInquiryKasbon(
              {
                ktp: res.data.ktpAddressDetails.idNo,
                cardNumber: res.data.respPayrollDetails.payrollAccount,
                bankCode: res.data.ktpAddressDetails.bankCode,
                token: auth.token,
                schemeCode: param?.schemeCode
              },
              {
                approve: param?.approve,
                reject: param?.reject
              }
            )
          );
          return;
        }

        param?.action();
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
        dispatch(loading(false));
      })
      .finally(() => {
        if (!param?.loading) dispatch(loading(false));
      });
  };
};

export const fetchingOptionSelect = (options, action = undefined) => {
  return async dispatch => {
    let promises = options.map(option => dispatch(fetchOptions(option)));
    let result = await Promise.all(promises).then(() => {
      action && action();
    });
    return result;
  };
};

export const fetchLoanScheme = auth => {
  return (dispatch, getState) => {
    dispatch(loading(true));

    const partnerId = getState().auth.partnerId;
    const url = `${USERS}/custom/loanNewSchemeCodes?partnerId=${partnerId}`;
    const header = {
      headers: {
        Authorization: auth.token
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        if (res.data.data) dispatch(setLoanSchemes(res.data.data));
        else dispatch(setError('system'));

        if (res.data.data[0].breakUpDetailsList)
          dispatch(setSubCodeLoanScheme(res.data.data[0].breakUpDetailsList));
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
          return;
        } else {
          dispatch(setError('system'));
          return;
        }
      })
      .finally(() => {
        if (auth.appStatus !== 'LOAN_CREATED') {
          if (partnerId === PARTNER_ID_GENERAL) {
            const newArrSubCode = getState().loanApply.subCodeLoanScheme.map(
              e => e.subCode.includes(getState().auth.subCode)
            );

            if (
              !getState().auth.subCode ||
              newArrSubCode.every(item => item === false)
            ) {
              dispatch(setError('system'));
            }
          }
        }

        dispatch(loading(false));
      });
  };
};

export const fetchLoanProduct = (auth, param) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + '/' + 'custom/loanproducts';
    const header = {
      headers: {
        Authorization: auth.token
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        res.data.data && dispatch(setLoanProduct(res.data.data));
        param && param.action && param.action();
      })
      .catch(err => {
        dispatch(loading(false));

        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
      })
      .finally(() => {
        param?.loading && dispatch(loading(false));
      });
  };
};

export const fetchCustCategory = (auth, param) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERSV2 + '/' + 'custom/applications/custcategory';
    const header = {
      headers: {
        Authorization: auth.token
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        res.data.data && dispatch(setCustCategory(res.data.data));
        param?.action();
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
      })
      .finally(() => {
        if (auth.isSecondApply !== 'Y') dispatch(loading(false));
      });
  };
};

export const fetchBankCodes = (token, action) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + '/' + 'custom/bankcodes/002';
    const header = {
      headers: {
        Authorization: token
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        if (res.data.data) {
          dispatch(
            setBankOptions(
              [{ value: '', label: '' }].concat(
                res.data.data[0].bankDetails.map(x => {
                  return {
                    value: x.bankCode,
                    label: x.bankName
                  };
                })
              )
            )
          );
          action && action();
          return;
        }
      })
      .catch(err => {
        dispatch(loading(false));

        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
      });
  };
};

export const atmInquiry = (data, action) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + '/' + 'custom/atminquiry';
    const body = {
      ktp: data.ktp,
      cardNumber: data.cardNumber,
      expiryDate: data.expiryDate,
      bankCode: data.bankCode,
      productCode: '002'
    };
    const header = {
      headers: {
        Authorization: data.token
      }
    };

    axiosGolang
      .post(url, body, header)
      .then(res => {
        const responseCode = res.data.code;

        if (responseCode === 211047 || responseCode === 211048) {
          dispatch(setError('contact-cs'));
          return;
        }

        if (responseCode === 211042) {
          dispatch(setError('expiry_date_mismatch'));
          return;
        }

        if (responseCode === 211043) {
          dispatch(setError('card_is_not_active'));
          return;
        }

        if (responseCode === 211049) {
          dispatch(setError('account_is_not_open'));
          return;
        }

        if (responseCode === 211050) {
          dispatch(setError('incorrect_ktp'));
          return;
        }

        if (responseCode === 211051) {
          dispatch(setError('entered_ktp_number_is_not_correct'));
          return;
        }

        if (responseCode === 211091) {
          dispatch(setError('user_unabled_to_do_atm_inquiry_for_15_minutes'));
          return;
        }

        if (responseCode === 211097) {
          dispatch(setError('nik_is_already_registered'));
          return;
        }

        if (responseCode === 110882) {
          dispatch(setError('incorrect_ktp'));
          return;
        }

        if (res.data.data) {
          dispatch(setAtmInqRes(res.data.data));
          dispatch(
            setCustomerName(
              res.data.data[0].silverLakeCIFInquiryReferenceVO.nama
            )
          );
          dispatch(
            setWhitelistAddress(
              res.data.data[0].silverLakeCIFInquiryReferenceVO.address,
              null
            )
          );
        }
        action(res.data.data[0]);
      })
      .catch(err => {
        sendEvent({
          category: 'Error ATM Inquiry Verification',
          action: JSON.stringify(err.response),
          label: data.phoneNo
        });

        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        if (err?.response?.status === 422) {
          dispatch(setError('miss_match'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          return;
        }

        dispatch(loading(false));
        dispatch(setError('system'));
      })
      .finally(() => dispatch(loading(false)));
  };
};

export const verifyPayroll = (auth, action, param) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + VERIFY_PAYROLL;
    const header = {
      headers: {
        Authorization: auth.token
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        dispatch(setVerifyPayroll(res.data.data));
        action && action(res.data.data);
        if (param?.loading) dispatch(loading(false));
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError('connection'));
          dispatch(loading(false));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '211049') {
          dispatch(setError('account_is_not_open'));
          dispatch(loading(false));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          dispatch(loading(false));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '211047') {
          dispatch(setError('contact-cs'));
          dispatch(loading(false));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '211053') {
          dispatch(setError('error_minimum_balance'));
          dispatch(loading(false));
          return;
        }

        dispatch(loading(false));
        dispatch(setError('system'));
      });
  };
};

//fungsi baru verifikasi
export const payrollVerification = (auth, body, action, history) => {
  return dispatch => {
    dispatch(setApplicationDetails(body));
    dispatch(loading(true));
    const baseUrl =
      body.applicationDetails.schemeCode === SCHEME_CODE_KASBON
        ? USERSV2
        : USERS;
    const url = baseUrl + '/' + 'custom/applications';
    const header = {
      headers: {
        Authorization: auth.token
      }
    };
    axiosGolang
      .post(url, body, header)
      .then(res => {
        const resCode = res?.data?.code;
        const status = res?.data?.data?.applicationStatus?.cmCode;
        dispatch(setApplicationId(res?.data?.data.applicationId));
        dispatch(setApplicationStatus(status));
        //dispatch(setNextDate(res.data.payrollrejdetails.nextEligibilityDate))

        if (resCode === 211091) {
          dispatch(setError('contact-cs'));
          return;
        }

        if (resCode === 211051) {
          dispatch(setError('contact-cs'));
          return;
        }

        if (status === 'PAYROLL_REJ') {
          localStorage.setItem(
            'dataVeryRek',
            JSON.stringify({
              bankCode: body.applicationDetails.bankCode,
              bankNumber: body.payrollSessionDetails.payrollAccount,
              ektp: body.applicationDetails.ktpNum
            })
          );
          history && history.push('/payroll-reject');
          return;
        }

        if (status === 'PAYROLL_APP') {
          body.applicationDetails.schemeCode === SCHEME_CODE_KASBON
            ? history && history.push('/form-fill')
            : history && history.push('/payroll-app');
        }

        action && action();
      })
      .catch(err => {
        if (err?.response?.status === 422) {
          dispatch(setError('system'));
          dispatch(setApplicationStatus('APP_CREATED'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '211101') {
          history && history.push('/payroll-reject');
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '1014') {
          dispatch(setError('system'));
          dispatch(setApplicationStatus('APP_CREATED'));
          dispatch(applicationInquiry(auth));
          return;
        }

        sendEvent({
          category: 'Error Payroll Verification',
          action: getJsonStringify(err.response),
          label: auth.phoneNo
        });

        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }
        dispatch(setError('system'));
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const submitUserDetails = (auth, param) => {
  return dispatch => {
    const url =
      USERS +
      '/' +
      (localStorage.getItem('phonePrincipal') || auth.phoneNo) +
      '/custom/' +
      COMPANY_INFO;
    const header = {
      headers: {
        Authorization: auth.token
      }
    };
    const body = {
      companyName: param.companyName,
      companyAddress: param.companyAddress
    };

    axios
      .post(url, body, header)
      .then(() => {
        param && param.action();
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
        dispatch(loading(false));
      });
  };
};

export const resubmitKTP = (auth, body, action) => {
  return dispatch => {
    const url = USERS + '/custom/applications/' + auth.applicationId;
    const config = {
      signal: controller.signal,
      headers: {
        Authorization: auth.token
      }
    };
    dispatch(setError(''));
    axiosGolang
      .put(url, body, config)
      .then(res => {
        dispatch(setApplicationId(res.data.data.applicationId));
        dispatch(setApplicationStatus(res.data.data.applicationStatus.cmCode));
        action.finish && action.finish();
      })
      .catch(err => {
        if (err.code === 'ERR_CANCELED') {
          return;
        }
        if (err.code === 'ERR_NETWORK') {
          dispatch(setError('connection'));
          return;
        }
        if (err.response && err.response.status === 400) {
          if (getChannelContextCode(err, 'channel-context') === null) {
            sendEvent({
              category: 'Unexpected Error KTP Upload',
              action: getJsonStringify(err.response),
              label: auth.phoneNo
            });
            sendException({
              desc: getJsonStringify(err.response),
              fatal: true
            });
            dispatch(setError('contact-cs'));
            dispatch(setEktpImg(''));
            return;
          }
          // KTP 15 Mins
          if (getChannelContextCode(err, 'channel-context') === '211091') {
            dispatch(setError('ktp_failed_15mins'));
            return;
          }
          if (getChannelContextCode(err, 'channel-context') === '211051') {
            dispatch(setError('incorrect_ktp'));
            return;
          }
        } else {
          dispatch(setError('contact-cs'));
          sendEvent({
            category: 'Unexpected Error KTP Upload',
            action: getJsonStringify(err.response),
            label: auth.phoneNo
          });
          sendException({
            desc: getJsonStringify(err.response),
            fatal: true
          });
          return;
        }
      })
      .finally(() => {
        dispatch(loading(false));
        action.show && action.show();
      });
  };
};

export const submitForm = (param, body, action) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + '/' + 'custom/applications/' + param.applicationId;
    const header = {
      headers: {
        Authorization: param.token
      }
    };
    axiosGolang
      .put(url, body, header)
      .then(res => {
        if (res.status.toString().slice(0, 1) === '2') {
          param.familyInfo && dispatch(setFamilyInfo(param.familyInfo));

          if (
            param.whitelistAddress &&
            param.whitelistAddress.fullAddress &&
            body.applicantInformation
          ) {
            setWhitelistAddress(
              param.whitelistAddress.fullAddress,
              body.applicantInformation
            );
          }

          if (
            param.mode === 'add' &&
            !param.personInfo &&
            !param.occupationInfo &&
            !param.familyInfo
          ) {
            action.push('/form-fill');
          }

          if (
            param.mode === 'edit' &&
            !param.occupationInfo &&
            !param.personInfo &&
            !param.familyInfo
          ) {
            action(prev => !prev);
          }

          if (param.mode === 'pause') {
            dispatch(setApplicationId(res.data.data.applicationId));
          }

          if (param.mode === 'submit' && res.data.data) {
            dispatch(setCreditScoringRes(res.data.data));
            dispatch(setApplicationId(res.data.data.applicationId));
            dispatch(
              setTimer({
                minutes: res.data.data.timeLeftForScoreCompletion,
                seconds: 0
              })
            );
            dispatch(setCreditScoreResult(''));
          }

          dispatch(
            setApplicationStatus(res.data.data.applicationStatus.cmCode)
          );

          return true;
        }
        return false;
      })
      .then(ok => {
        if (!ok && !param.occupationInfo) return;
        action && action();
      })
      .catch(err => {
        dispatch(setHomePath('/continue-apply'));

        if (getChannelContextCode(err, 'channel-context') === '211053') {
          dispatch(setError('error_minimum_balance'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '11017') {
          dispatch(setError('same-phonenum-relationship'));
          return;
        }

        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
        dispatch(loading(false));
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const fetchProvinceOptions = callback => {
  return (dispatch, getState) => {
    dispatch(loading(true));

    axiosGolang
      .get(PROVINCE, { headers: { Authorization: getState().auth.VTtoken } })
      .then(res => {
        dispatch(
          setProvinceOptions(
            [{ value: '', label: '' }].concat(
              res.data.map(data => {
                return {
                  value: data.stateCode,
                  label: data.stateDescription
                };
              })
            )
          )
        );

        callback && callback();
      })
      .catch(err => {
        dispatch(setHomePath('/continue-apply'));
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
        dispatch(loading(false));
      });
  };
};

export const fetchFieldOfBusinessOptions = callback => {
  return (dispatch, getState) => {
    dispatch(loading(true));

    axiosGolang
      .get(FIELD_OF_BUSINESS, {
        headers: { Authorization: getState().auth.VTtoken }
      })
      .then(res => {
        dispatch(
          setFieldOfBusinessOptions(
            [{ value: '', label: '' }].concat(
              res.data.map(data => {
                return {
                  value: data.cmCode,
                  label: data.codeDescription
                };
              })
            )
          )
        );

        callback && callback();
      })
      .catch(err => {
        dispatch(setHomePath('/continue-apply'));
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
        dispatch(loading(false));
      });
  };
};
export const fetchFieldOfWorkOptions = callback => {
  return (dispatch, getState) => {
    dispatch(loading(true));

    axiosGolang
      .get(FIELD_OF_WORKS, {
        headers: { Authorization: getState().auth.VTtoken }
      })
      .then(res => {
        dispatch(
          setFieldOfWorkOptions(
            [{ value: '', label: '' }].concat(
              res.data.map(data => {
                return {
                  value: data.cmCode,
                  label: data.codeDescription
                };
              })
            )
          )
        );

        callback && callback();
      })
      .catch(err => {
        dispatch(setHomePath('/continue-apply'));
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
        dispatch(loading(false));
      });
  };
};

/**
 * Get list from API to be filled up on UI
 * @param path
 * @param code
 * @param callback after success get list
 * @returns {function(...[*]=)}
 */
export const fetchOptions = (path, code, callback) => {
  return (dispatch, getState) => {
    dispatch(loading(true));
    const url = CODE_REFERENCES + path;
    const header = {
      headers: {
        Authorization: getState().auth.VTtoken
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        if (path === '/LNPS') {
          dispatch(
            setLoanPurposeOptions(
              [{ value: '', label: '' }].concat(
                res.data.map(data => {
                  return {
                    value: data.cmCode,
                    label: data.codeDescription
                  };
                })
              )
            )
          );
        } else if (path === '/EDU') {
          dispatch(
            setEducationOptions(
              [{ value: '', label: '' }].concat(
                res.data.map(data => {
                  return {
                    value: data.cmCode,
                    label: data.codeDescription
                  };
                })
              )
            )
          );
        } else if (path === '/CCBK') {
          dispatch(
            setCreditCardOptions(
              [{ value: '', label: '' }].concat(
                res.data.map(data => {
                  return {
                    value: data.cmCode,
                    label: data.codeDescription
                  };
                })
              )
            )
          );
        } else if (path === '/RES') {
          dispatch(
            setResidenceOptions(
              [{ value: '', label: '' }].concat(
                res.data.map(data => {
                  return {
                    value: data.cmCode,
                    label: data.codeDescription
                  };
                })
              )
            )
          );
        } else if (path === '/CTOW') {
          dispatch(
            setTypeOfWorkOptions(
              [{ value: '', label: '' }].concat(
                res.data.map(data => {
                  return {
                    value: data.cmCode,
                    label: data.codeDescription
                  };
                })
              )
            )
          );
        } else if (path === '/EMES') {
          dispatch(
            setEmployeeOptions(
              [{ value: '', label: '' }].concat(
                res.data.map(data => {
                  return {
                    value: data.codeDescription,
                    label: data.codeDescription
                  };
                })
              )
            )
          );
        } else if (path === '/CPOA') {
          dispatch(
            setPurposeOfOpeningAccountOptions(
              [{ value: '', label: '' }].concat(
                res.data.map(data => {
                  return {
                    value: data.cmCode,
                    label: data.codeDescription
                  };
                })
              )
            )
          );
        } else if (path === '/CSOI') {
          dispatch(
            setSourceOfIncomeOptions(
              [{ value: '', label: '' }].concat(
                res.data.map(data => {
                  return {
                    value: data.cmCode,
                    label: data.codeDescription
                  };
                })
              )
            )
          );
        } else if (path === '/CNY') {
          dispatch(
            setCompanyOptions(
              res.data.map(data => {
                return {
                  value: data.codeDescription,
                  label: data.codeDescription
                };
              })
            )
          );
        } else if (path === '/MST') {
          dispatch(
            setMarriageOptions(
              [{ value: '', label: '' }].concat(
                res.data.map(data => {
                  return {
                    value: data.cmCode,
                    label: data.codeDescription
                  };
                })
              )
            )
          );
        } else if (path === '/DEPN') {
          dispatch(
            setDependentsOptions(
              [{ value: '', label: '' }].concat(
                res.data.map(data => {
                  return {
                    value: data.cmCode,
                    label: data.codeDescription
                  };
                })
              )
            )
          );
        } else if (path === '/RLT') {
          dispatch(
            setRelativesOptions(
              [{ value: '', label: '' }].concat(
                res.data.map(data => {
                  return {
                    value: data.cmCode,
                    label: data.codeDescription
                  };
                })
              )
            )
          );
        } else if (/\d/.test(path)) {
          if (code === 'kota') {
            dispatch(
              setCityOptions(
                [{ value: '', label: '' }].concat(
                  res.data.map(data => {
                    return {
                      value: data.cmCode,
                      label: data.codeDescription
                    };
                  })
                )
              )
            );
          } else if (code === 'kecamatan') {
            dispatch(
              setSubDistrictOptions(
                [{ value: '', label: '' }].concat(
                  res.data.map(data => {
                    return {
                      value: data.cmCode,
                      label: data.codeDescription
                    };
                  })
                )
              )
            );
          } else if (code === 'desa') {
            dispatch(
              setVillageOptions(
                [{ value: '', label: '' }].concat(
                  res.data.map(data => {
                    return {
                      value: data.cmCode,
                      label: data.codeDescription
                    };
                  })
                )
              )
            );
          } else if (code === '/SUBBDSH') {
            dispatch(
              setFieldOfSubBusinessOptions(
                [{ codeType: '', value: '', label: '' }].concat(
                  res.data.map(data => {
                    return {
                      codeType: data.codeType,
                      value: data.cmCode,
                      label: data.codeDescription
                    };
                  })
                )
              )
            );
          }
        }
      })
      .then(() => {
        callback && callback();
      })
      .catch(err => {
        if (path !== '/LNPS') dispatch(setHomePath('/continue-apply'));
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
        dispatch(loading(false));
      });
  };
};

export const submitCreditScoring = (param, body, action) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + '/' + 'custom/applications/' + param.applicationId;
    const header = {
      headers: {
        Authorization: param.token
      }
    };

    axiosGolang
      .put(url, body, header)
      .then(res => {
        dispatch(setCreditScoringRes(res.data.data));
        dispatch(
          setApplicationStatus(res.data.data?.applicationStatus?.cmCode)
        );
        localStorage.setItem('setCSFromFormFill', 1);
        dispatch(
          setTimer({
            minutes: res.data.data.timeLeftForScoreCompletion,
            seconds: 0
          })
        );
        dispatch(setCreditScoreResult(''));
        action && action();
      })
      .catch(err => {
        dispatch(setHomePath('/continue-apply'));
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          return;
        }

        // OCR FAILED
        if (getChannelContextCode(err, 'channel-context') === '211051') {
          dispatch(setError('OCR_FAILED'));
          return;
        }

        // PAYROLL ERROR
        if (getChannelContextCode(err, 'channel-context') === '211101') {
          dispatch(setError('PAYROLL_ERROR'));
          return;
        }
        dispatch(setError('system'));
        sendEvent({
          category: 'Unexpected Error Credit Scoring 1st Loan',
          action: getJsonStringify(err.response),
          label: param.phoneNo
        });
        sendException({
          desc: getJsonStringify(err.response),
          fatal: true
        });
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const secondLoanCreditScoring = (param, body, action) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + '/custom/applications/' + param.applicationId;
    const header = {
      headers: {
        Authorization: param.token,
        'Content-Type': 'application/json'
      }
    };

    axiosGolang
      .post(url, body, header)
      .then(res => {
        dispatch(setCreditScoringRes(res.data.data));
        localStorage.setItem('setCSFromFormFill', 1);
        dispatch(setApplicationId(res.data.data.applicationId));
        dispatch(setApplicationStatus(res.data.data.applicationStatus.cmCode));
        dispatch(
          setTimer({
            minutes: res.data.data.timeLeftForScoreCompletion,
            seconds: 0
          })
        );
        dispatch(setCreditScoreResult(''));
        action && action();
      })
      .catch(err => {
        dispatch(setHomePath('/continue-apply'));
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        // SAVING AMOUNT <= 0
        if (getChannelContextCode(err, 'channel-context') === '211053') {
          dispatch(setError('error_minimum_balance'));
          return;
        }
        if (getChannelContextCode(err, 'channel-context') === '11017') {
          dispatch(setError('same-phonenum-relationship'));
          return;
        }

        if (
          err.code === 'ERR_BAD_REQUEST' ||
          getChannelContextCode(err, 'channel-context') === null
        ) {
          dispatch(setError('system'));
          return;
        }

        // OCR FAILED
        if (getChannelContextCode(err, 'channel-context') === '211051') {
          dispatch(setError('OCR_FAILED'));
          return;
        }

        // PAYROLL ERROR
        if (getChannelContextCode(err, 'channel-context') === '211101') {
          dispatch(setError('PAYROLL_ERROR'));
          return;
        }

        dispatch(setError('system'));

        sendEvent({
          category: 'Unexpected Error Credit Scoring 2nd Loan',
          action: getJsonStringify(err.response),
          label: param.phoneNo
        });
        sendException({
          desc: getJsonStringify(err.response),
          fatal: true
        });
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const setApplicationdata = data => {
  return {
    type: actionTypes.RESUME_APPLICATION,
    res: data
  };
};

export const setLoanProduct = product => {
  return {
    type: actionTypes.SET_LOAN_PRODUCT_OPTIONS,
    product: product
  };
};

export const setCustCategory = custCategory => {
  return {
    type: actionTypes.SET_CUST_CATEGORY_OPTIONS,
    custCategory: custCategory
  };
};

export const setLoanAmount = amount => {
  return {
    type: actionTypes.SET_LOAN_AMOUNT,
    amount: amount
  };
};

export const setTenure = tenure => {
  return {
    type: actionTypes.SET_TENURE,
    tenure: tenure
  };
};

export const setLoanPurpose = purpose => {
  return {
    type: actionTypes.SET_LOAN_PURPOSE,
    purpose: purpose
  };
};

export const setAccountNumber = accountNumber => {
  return {
    type: actionTypes.SET_ACCOUNT_NUMBER,
    accountNumber: accountNumber
  };
};

export const registerWhitelist = (data, token, history) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + '/' + 'custom/payroll-upload';
    const body = data;
    const header = {
      headers: {
        Authorization: token
      }
    };

    axiosGolang
      .post(url, body, header)
      .then(res => {
        const responseCode = res.data.code;
        if (responseCode === '001') {
          dispatch(setError('failed_insert'));
          return;
        }
        if (responseCode === '000') {
          history && history.push('/whitelist-success');
        }
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '1003') {
          dispatch(setError('failed_insert'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          return;
        }

        if (err.response && err.response.status === 422) {
          dispatch(setError('miss_match'));
          return;
        }
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const accountInquiryInstallment = (data, action) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + '/' + 'custom/accountinquiry';
    const body = {
      ktp: data.ktp,
      acctNo: data.cardNumber,
      bankCode: data.bankCode,
      productCode: '002'
    };
    const header = {
      headers: {
        Authorization: data.token
      }
    };

    axiosGolang
      .post(url, body, header)
      .then(res => {
        const responseCode = res.data.code;

        if (responseCode) dispatch(loading(false));

        if (responseCode === 211047 || responseCode === 211048) {
          dispatch(setError('contact-cs'));
          return;
        }

        if (responseCode === 211042) {
          dispatch(setError('expiry_date_mismatch'));
          return;
        }

        if (responseCode === 211049) {
          dispatch(setError('account_is_not_open'));
          return;
        }

        if (responseCode === 211050) {
          dispatch(setError('incorrect_ktp'));
          return;
        }

        if (responseCode === 1008) {
          dispatch(setError('contact-cs'));
          return;
        }

        if (responseCode === 211051) {
          dispatch(setError('entered_ktp_number_is_not_correct'));
          return;
        }

        if (responseCode === 211091) {
          dispatch(setError('user_unabled_to_do_atm_inquiry_for_15_minutes'));
          return;
        }

        if (responseCode === 211097) {
          dispatch(setError('nik_is_already_registered'));
          return;
        }

        if (responseCode === 211052) {
          dispatch(setError('getting_older_young'));
          return;
        }

        if (responseCode === 211053) {
          dispatch(setError('error_minimum_balance'));
          return;
        }

        if (res.data.data) {
          dispatch(setAtmInqRes(res.data.data));
          dispatch(
            setCustomerName(
              res.data.data[0].silverLakeCIFInquiryReferenceVO.nama
            )
          );
          dispatch(
            setWhitelistAddress(
              res.data.data[0].silverLakeCIFInquiryReferenceVO.address,
              null
            )
          );
        }

        action && action?.approve && action?.approve();
        action && action?.payrollverf && action?.payrollverf(res.data.data[0]);
      })
      .catch(err => {
        sendEvent({
          category: 'Error ATM Inquiry Verification',
          action: JSON.stringify(err.response),
          label: data.phoneNo
        });

        dispatch(loading(false));

        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        if (err.response && err.response.status === 422) {
          dispatch(setError('miss_match'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '1026') {
          dispatch(setError('contact-cs'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          return;
        }
        dispatch(setError('system'));
      });
  };
};

export const accountInquiryKasbon = (data, action) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERSV2 + '/' + 'custom/accountinquiry';
    const bodyKsbon = {
      ktp: data.ktp,
      acctNo: data.cardNumber,
      bankCode: data.bankCode,
      productCode: '002',
      schemeCode: data.schemeCode
    };
    const header = {
      headers: {
        Authorization: data.token
      }
    };

    axiosGolang
      .post(url, bodyKsbon, header)
      .then(res => {
        const responseCode = res.data.code;

        if (responseCode) dispatch(loading(false));

        if (responseCode === 211047 || responseCode === 211048) {
          dispatch(setError('contact-cs'));
          return;
        }

        if (responseCode === 211042) {
          dispatch(setError('expiry_date_mismatch'));
          return;
        }

        if (responseCode === 211049) {
          dispatch(setError('account_is_not_open'));
          return;
        }

        if (responseCode === 211050) {
          dispatch(setError('incorrect_ktp'));
          return;
        }

        if (responseCode === 1008) {
          dispatch(setError('contact-cs'));
          return;
        }

        if (responseCode === 211051) {
          dispatch(setError('entered_ktp_number_is_not_correct'));
          return;
        }

        if (responseCode === 211091) {
          dispatch(setError('user_unabled_to_do_atm_inquiry_for_15_minutes'));
          return;
        }

        if (responseCode === 211097) {
          dispatch(setError('nik_is_already_registered'));
          return;
        }

        if (responseCode === 211052) {
          dispatch(setError('getting_older_young'));
          return;
        }

        if (responseCode === 211053) {
          dispatch(setError('error_minimum_balance'));
          return;
        }

        if (
          data.schemeCode === SCHEME_CODE_KASBON &&
          isObjectEmpty(res.data.data[0].whitelistReferenceVo)
        ) {
          dispatch(setError('no_data_whitelist'));
          return;
        }

        if (res.data.data[0].silverLakeCIFInquiryReferenceVO) {
          dispatch(setAtmInqRes(res.data.data));
          dispatch(
            setCustomerName(
              res.data.data[0].silverLakeCIFInquiryReferenceVO.nama
            )
          );
          dispatch(
            setWhitelistAddress(
              res.data.data[0].silverLakeCIFInquiryReferenceVO.address,
              null
            )
          );
          dispatch(
            setPayrollDateWhitelist(
              res.data.data[0].whitelistReferenceVo?.payrollDate
            )
          );
        } else {
          if (
            data.schemeCode === SCHEME_CODE_KASBON &&
            isObjectEmpty(
              res.data.data[0].applicationStatus?.cmCode === 'PAYROLL_REJ'
            )
          ) {
            localStorage.setItem(
              'dataVeryRek',
              JSON.stringify({
                bankCode: data.bankCode,
                bankNumber: data.cardNumber,
                ektp: data.ktp
              })
            );
            action && action.reject();
            dispatch(loading(false));
            return;
          }
        }
        action && action.approve();
      })
      .catch(err => {
        sendEvent({
          category: 'Error ATM Inquiry Verification',
          action: JSON.stringify(err.response),
          label: data.phoneNo
        });

        dispatch(loading(false));

        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        if (err.response && err.response.status === 422) {
          dispatch(setError('miss_match'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '1026') {
          dispatch(setError('contact-cs'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          return;
        }
        dispatch(setError('system'));
      });
  };
};

export const setProductConfig = config => {
  return {
    type: actionTypes.SET_PRODUCT_CONFIG,
    rate: config.rate,
    code: config.code,
    category: config.category,
    subCategory: config.subCategory,
    schemeCode: config.schemeCode,
    installment: config.installment
  };
};

export const setApplicationId = id => {
  return {
    type: actionTypes.SET_APPLICATION_ID,
    id: id
  };
};

export const setApplicationStatus = status => {
  return {
    type: actionTypes.SET_APPLICATION_STATUS,
    status: status
  };
};

export const setNextDate = nextDate => {
  return {
    type: actionTypes.SET_NEXT_DATE,
    nextDate: nextDate
  };
};

export const setAppInqRes = res => {
  return {
    type: actionTypes.SET_APP_INQUIRY_RESPONSE,
    res: res
  };
};

export const setApprovalDetails = data => {
  return {
    type: actionTypes.SET_APPROVAL_DETAILS,
    data: data
  };
};

export const setIncompleteApp = data => {
  return {
    type: actionTypes.SET_INCOMPLETE_APP,
    data: data
  };
};
export const setEktp = ektp => {
  return {
    type: actionTypes.SET_EKTP,
    ektp: ektp
  };
};

export const setBankCode = source => {
  return {
    type: actionTypes.SET_BANK_CODE,
    source: source
  };
};

export const setCardNumber = cardNo => {
  return {
    type: actionTypes.SET_CARD_NUMBER,
    cardNumber: cardNo
  };
};

export const setCardExpDate = expDate => {
  return {
    type: actionTypes.SET_CARD_EXPIRED_DATE,
    expDate: expDate
  };
};

export const setAtmInqRes = response => {
  return {
    type: actionTypes.SET_ATM_INQUIRY_RESPONSE,
    response: response
  };
};

export const setCustomerName = custName => {
  return {
    type: actionTypes.SET_CUSTOMER_NAME,
    name: custName
  };
};

export const setDisburseLifetime = disburseLifetime => {
  return {
    type: actionTypes.SET_DISBURSE_LIFETIME,
    disburseLifetime: disburseLifetime
  };
};

export const setSubtractionDisburseLifeTime = () => {
  return {
    type: actionTypes.SET_SUBTRACTION_DISBURSE_LIFETIME
  };
};

export const setPayrollDateWhitelist = payrollDateWhitelist => {
  return {
    type: actionTypes.SET_PAYROLL_DATE_WHITELIST,
    payrollDateWhitelist: payrollDateWhitelist
  };
};

export const setLoanPurposeOptions = options => {
  return {
    type: actionTypes.SET_LOAN_PURPOSE_OPTIONS,
    options: options
  };
};

export const setBankOptions = bankCodes => {
  return {
    type: actionTypes.SET_BANK_OPTIONS,
    bankCodes: bankCodes
  };
};

export const setEducationOptions = options => {
  return {
    type: actionTypes.SET_EDUCATION_OPTIONS,
    options: options
  };
};
export const setFieldOfWorkOptions = options => {
  return {
    type: actionTypes.SET_FIELD_OF_WORK_OPTIONS,
    options: options
  };
};
export const setFieldOfBusinessOptions = options => {
  return {
    type: actionTypes.SET_FIELD_OF_BUSINESS_OPTIONS,
    options: options
  };
};

export const setFieldOfSubBusinessOptions = options => {
  return {
    type: actionTypes.SET_FIELD_OF_SUB_BUSINESS_OPTIONS,
    options: options
  };
};

export const setCreditCardOptions = options => {
  return {
    type: actionTypes.SET_CREDIT_CARD_OPTIONS,
    options: options
  };
};

export const setProvinceOptions = options => {
  return {
    type: actionTypes.SET_PROVINCE_OPTIONS,
    options: options
  };
};

export const setCityOptions = options => {
  return {
    type: actionTypes.SET_CITY_OPTIONS,
    options: options
  };
};

export const setSubDistrictOptions = options => {
  return {
    type: actionTypes.SET_SUB_DISTRICT_OPTIONS,
    options: options
  };
};

export const setVillageOptions = options => {
  return {
    type: actionTypes.SET_VILLAGE_OPTIONS,
    options: options
  };
};

export const setResidenceOptions = options => {
  return {
    type: actionTypes.SET_RESIDENCE_OPTIONS,
    options: options
  };
};

export const setTypeOfWorkOptions = options => {
  return {
    type: actionTypes.SET_TYPE_OF_WORK_OPTIONS,
    options: options
  };
};

export const setEmployeeOptions = options => {
  return {
    type: actionTypes.SET_EMPLOYEE_OPTIONS,
    options: options
  };
};

export const setPurposeOfOpeningAccountOptions = options => {
  return {
    type: actionTypes.SET_PURPOSE_OF_OPENING_ACCOUNT_OPTIONS,
    options: options
  };
};

export const setSourceOfIncomeOptions = options => {
  return {
    type: actionTypes.SET_SOURCE_OF_INCOME_OPTIONS,
    options: options
  };
};

export const setCompanyOptions = options => {
  return {
    type: actionTypes.SET_COMPANY_OPTIONS,
    options: options
  };
};

export const setDependentsOptions = options => {
  return {
    type: actionTypes.SET_DEPENDENTS_OPTIONS,
    options: options
  };
};

export const setMarriageOptions = options => {
  return {
    type: actionTypes.SET_MARRIAGE_OPTIONS,
    options: options
  };
};

export const setRelativesOptions = options => {
  return {
    type: actionTypes.SET_RELATIVES_OPTIONS,
    options: options
  };
};

export const setVerifyPayroll = data => {
  return {
    type: actionTypes.SET_VERIFY_PAYROLL,
    data: data
  };
};

export const setApplicationDetails = data => {
  return {
    type: actionTypes.SET_APPLICATION_DETAILS,
    data: data
  };
};

export const setDocumentDetails = data => {
  return {
    type: actionTypes.SET_DOCUMENT_DETAILS,
    data: data
  };
};

export const setRespPayrollDetails = data => {
  return {
    type: actionTypes.SET_RESP_PAYROLL_DETAILS,
    data: data
  };
};

export const setEktpImg = value => {
  return {
    type: actionTypes.SET_EKTP_IMG,
    value: value
  };
};

export const setPersonInfo = data => {
  return {
    type: actionTypes.SET_PERSON_INFO,
    personInfo: data
  };
};

export const setKtpAddressDetails = data => {
  return {
    type: actionTypes.SET_KTP_ADDRESS_DETAILS,
    data: data
  };
};

export const setOccupationInfo = data => {
  return {
    type: actionTypes.SET_OCCUPATION_INFO,
    occupationInfo: data
  };
};

export const setFamilyInfo = data => {
  return {
    type: actionTypes.SET_FAMILY_INFO,
    familyInfo: data
  };
};

export const setCreditScoringRes = res => {
  return {
    type: actionTypes.CREDIT_SCORE_RESPONSE,
    res: res
  };
};

export const setWhitelistAddress = (whitelistAddress, applicantDetails) => {
  const addresses = whitelistAddress && whitelistAddress.split('|');
  const fullAddress = addresses && addresses.length > 0 ? addresses[0] : '';
  const address = {
    fullAddress,
    haveSameAddress: false
  };

  if (applicantDetails && applicantDetails.addressLine1) {
    Object.assign(address, {
      haveSameAddress: applicantDetails.addressLine1 === fullAddress
    });
  }

  return {
    type: actionTypes.SET_WHITELIST_ADDRESS,
    whitelistAddress: address
  };
};

export const setSecondApply = data => {
  return {
    type: actionTypes.SET_SECOND_APPLY,
    set: data
  };
};

export const setCompanyName = val => {
  return {
    type: actionTypes.SET_COMPANY_NAME,
    data: val
  };
};

export const setCompanyAddress = val => {
  return {
    type: actionTypes.SET_COMPANY_ADDRESS,
    data: val
  };
};

export const dehydrateData = () => {
  return {
    type: actionTypes.SET_DEHYDRATE_DATA
  };
};

export const setSubCodeLoanScheme = payload => {
  return {
    type: actionTypes.SET_SUBCODE_LOANSCHEME,
    payload: payload
  };
};

export const setLoanSchemes = payload => {
  return {
    type: actionTypes.SET_LOAN_SCHEMES,
    payload: payload
  };
};

export const setLoanScheme = payload => {
  return {
    type: actionTypes.SET_LOAN_SCHEME,
    payload: payload
  };
};

export const setLoanFee = payload => {
  return {
    type: actionTypes.SET_LOAN_FEE,
    payload: payload
  };
};

export const setAttemptSubmission = payload => {
  return {
    type: actionTypes.SET_ATTEMPT_SUBMISSION,
    payload
  };
};

export const setShouldManualCheck = payload => {
  return {
    type: actionTypes.SHOULD_MANUAL_CHECK,
    payload
  };
};

export const setNPWP = payload => {
  return {
    type: actionTypes.SET_NPWP,
    payload
  };
};

export const handleAbortResubmitKTP = (isAbort = false) => {
  return dispatch => {
    if (isAbort) {
      controller.abort();
      dispatch(setError('ERR_CANCELED'));
      return;
    }
    controller = new AbortController();
    return;
  };
};

export const setAlterNativeAmount = payload => {
  return {
    type: actionTypes.SET_ALTERNATIVE_AMOUNT,
    payload
  };
};

export const setAlternativeTenure = payload => {
  return {
    type: actionTypes.SET_ALTERNATIVE_TENURE,
    payload
  };
};
