import * as actionTypes from './actionTypes';
import {
  applicationInquiry,
  loading,
  setBankCode,
  setApplicationStatus
} from './loanApply';
import { setError, setIsLogin } from './auth';
import { setLoans } from './history';
import axios from '../../lib/axios/axios';
import axiosGolang from 'lib/axios/axios-golang';
import {
  USERS,
  STANDARD_TEXT,
  ELEMENT_GET_IMAGES,
  CUSTOM_URL,
  USERSV2
} from '../../common/config';

import { SendEventDisbursement, sendException } from 'helpers/analytics';
import { downloadPDF } from 'helpers/downloadFile';
import { getChannelContextCode } from 'helpers/Utility';

import {
  Photo_Not_Matched,
  Nik_And_Photo_Not_Match,
  Photo_Not_Detected,
  Nik_Not_Registered,
  INVALID_DEMOGRAPHIC,
  LIVENESS_FAILED,
  EKYC_FAILED_CONTACT_CODES
} from 'common/constants';

export const editApplicationStatus = (auth, param) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + '/' + '/custom/applications/' + auth.applicationId;
    const header = {
      headers: {
        Authorization: auth.token
      }
    };

    const bodyReject = {
      applicationDetails: {
        newStatus: param.status,
        refusalReason: param.refusalReason
      }
    };

    const bodyApprove = {
      applicationDetails: {
        newStatus: param.status
      }
    };

    axiosGolang
      .put(
        url,
        param.status === 'USR_REJECT' ? bodyReject : bodyApprove,
        header
      )
      .then(res => {
        dispatch(setApplicationStatus(res.data.data.applicationStatus.cmCode));
        dispatch(setPassiveLivenessImage(''));
        param.push && param.push();
        dispatch(loading(false));
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
          dispatch(loading(false));
        } else {
          dispatch(setError('system'));
          dispatch(loading(false));
        }
      });
  };
};

export const submitPlafondAndTenure = (auth, param) => {
  return dispatch => {
    dispatch(loading(true));
    const url =
      USERS +
      '/custom/applications/' +
      auth.applicationId +
      '/updateLoanDetails';
    const header = {
      headers: {
        Authorization: auth.token
      }
    };

    const body = {
      selectedPlafond: Number(param.plafond),
      selectedTenor: param.tenure,
      processingFee: param?.feeAmount
    };

    axiosGolang
      .put(url, body, header)
      .then(() => {
        param && param?.push && param?.push();
        dispatch(loading(false));
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
          dispatch(loading(false));
        } else {
          dispatch(setError('system'));
          dispatch(loading(false));
        }
      });
  };
};

export const checkBankCode = auth => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + '/custom/bankcode/' + auth.applicationId;
    const header = {
      headers: {
        Authorization: auth.token
      }
    };
    axiosGolang
      .get(url, header)
      .then(res => {
        dispatch(setBankCode(res.data.data[0].bankCode));
        dispatch(loading(false));
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
          dispatch(loading(false));
        } else {
          dispatch(setError('system'));
          dispatch(loading(false));
        }
      });
  };
};

export const retrieveSelfieElement = (auth, action) => {
  return dispatch => {
    // dispatch(loading(true))
    const url = ELEMENT_GET_IMAGES + auth.txnId + '&withResult=true';
    const header = {
      headers: {
        CLIENTID: auth.clientId,
        TIMESTAMP: auth.timeStamp,
        HASHTOKEN: auth.hashToken
      }
    };
    axios
      .get(url, header)
      .then(res => {
        const status = res.data.status;
        if (status) {
          dispatch(setApplicationStatus(''));
          const pic1 = 'data:image/jpeg;base64,' + res.data.images[1].data;
          const pic2 = 'data:image/jpeg;base64,' + res.data.images[3].data;
          dispatch(takeSelfiePrivy(pic1, pic2, status));
          dispatch(
            privyRegistration(auth, {
              fileData1: res.data.images[1].data,
              fileData2: res.data.images[3].data
            })
          );
        } else {
          dispatch(takeSelfiePrivy('', '', status));
        }
        dispatch(loading(false));
      })
      .catch(err => {
        if (err.response && err.response.status === 403) {
          dispatch(setError('sesi_habis'));
          dispatch(loading(false));
        } else if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
          dispatch(loading(false));
        } else {
          dispatch(setError('system'));
          dispatch(loading(false));
        }
      })
      .finally(() => {
        action && action();
      });
  };
};

export const passiveLiveness = (auth, param) => {
  return (dispatch, getState) => {
    param?.verif && dispatch(loading(true));
    const url = `${USERS}/custom/face/liveness`;
    const body = {
      base64Image: param.base64Image
    };

    const header = {
      headers: {
        Authorization: auth.token
      },
      signal: param.signal()
    };

    axiosGolang
      .post(url, body, header)
      .then(res => {
        // Failed Liveness
        if (res.data.data.code === 211153) {
          dispatch(setError(LIVENESS_FAILED));
          SendEventDisbursement({
            action: 'Liveness Failed',
            isSecondApply: getState().loanApply.isSecondApply
          });
          sendException({
            desc: 'Liveness Failed',
            fatal: 'true'
          });
          return;
        }

        // Success Liveness
        if (res.data.data.code === 1043) {
          !param?.verif &&
            dispatch(
              ekycVerification(auth, {
                selfieData: param?.base64Image,
                action: param?.action
              })
            );
          param?.verif && param?.action && param.action();
          return;
        }
      })
      .catch(err => {
        if (err.code === 'ERR_CANCELED') return;

        if (!err.response || err.code === 'ERR_NETWORK') {
          dispatch(setError('connection'));
          param?.verif && dispatch(loading(false));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('privysys_fail'));
          SendEventDisbursement({
            action: 'Unexpected Error Liveness',
            isSecondApply: getState().loanApply.isSecondApply
          });
          sendException({
            desc: 'Unexpected Error Liveness',
            fatal: 'true'
          });
          return;
        }

        // Passive liveness failed
        if (
          getChannelContextCode(err, 'channel-context') === '211153' ||
          getChannelContextCode(err, 'channel-context') === '1039'
        ) {
          dispatch(setError(LIVENESS_FAILED));
          SendEventDisbursement({
            action: 'Liveness Failed',
            isSecondApply: getState().loanApply.isSecondApply
          });
          sendException({
            desc: 'Liveness Failed',
            fatal: 'true'
          });
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '1007') {
          dispatch(setError('vida_failed'));
          SendEventDisbursement({
            action: 'Vida Failed',
            isSecondApply: getState().loanApply.isSecondApply
          });
          sendException({
            desc: 'Vida Failed',
            fatal: 'true'
          });
          return;
        }
      })
      .finally(() => param?.verif && dispatch(loading(false)));
  };
};

export const ekycVerification = (auth, param) => {
  return (dispatch, getState) => {
    const url = `${USERS}/${CUSTOM_URL}/${
      getState()?.loanApply?.applicationId
    }/ekyc`;

    const body = {
      selfiePhoto: param.selfieData
    };

    const header = {
      headers: {
        Authorization: getState()?.auth?.oauthToken,
        'Content-Type': 'application/json'
      }
    };

    axiosGolang
      .post(url, body, header)
      .then(res => {
        const statusCode = res?.data?.code;

        if (EKYC_FAILED_CONTACT_CODES.includes(statusCode)) {
          dispatch(setError(INVALID_DEMOGRAPHIC));
          return;
        }

        if (statusCode === 5003 || statusCode === 211153) {
          dispatch(setError(Photo_Not_Matched));
          return;
        }

        if (statusCode === 405 || statusCode === 422) {
          dispatch(setError('system'));
          return;
        }

        dispatch(setEkycKey(res?.data?.data?.kycEventId));
        dispatch(ekycCheckingStatus(auth));
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
      })
      .finally(() => dispatch(loading(false)));
  };
};

export const ekycCheckingStatus = auth => {
  return (dispatch, getState) => {
    const url = `${USERS}/${CUSTOM_URL}/${auth?.applicationId}/ekyc/status`;

    const header = {
      headers: {
        Authorization: auth?.token,
        'Content-Type': 'application/json'
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        const status = res.data.status;
        dispatch(setEkycStatus(status));

        if (status === 'true') {
          dispatch(setApplicationStatus('EKYC_COM'));
          SendEventDisbursement({
            action: 'User Successfully EKYC Verification Dukcapil',
            isSecondApply: getState().loanApply.isSecondApply
          });
          return;
        }

        if (status === 'false') {
          //todo : handle to retry selfie again
          dispatch(setError(Nik_And_Photo_Not_Match));
          return;
        }

        if (status === 'inprogress') {
          //todo: Handle loading for a while
          setTimeout(() => dispatch(ekycCheckingStatus(auth)), 3000);
        }
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
      });
  };
};

export const privyRegistration = (auth, param) => {
  return (dispatch, getState) => {
    param?.accept && dispatch(loading(true));
    const url = USERS + '/' + 'custom/privyregistrations';
    const body1 = {
      applicationId: auth.applicationId,
      file1: {
        fileData: param.fileData1,
        fileName: auth.applicationId + '.jpg'
      },
      file2: {
        fileData: param.fileData2,
        fileName: auth.applicationId + '.jpg'
      }
    };
    const body2 = {
      applicationId: auth.applicationId,
      file1: {
        fileData: '',
        fileName: ''
      },
      file2: {
        fileData: '',
        fileName: ''
      }
    };
    const header = {
      headers: {
        Authorization: auth.token
      }
    };
    axiosGolang
      .post(url, param.accept ? body2 : body1, header)
      .then(res => {
        if (!param.accept) {
          dispatch(setApplicationStatus('EKYC_COM'));
          SendEventDisbursement({
            action: 'User Successfully EKYC Verification Dukcapil',
            isSecondApply: getState().loanApply.isSecondApply
          });
        }
        dispatch(setPrivyRegData(res.data));
        param.action && param.action();
        // dispatch(loading(false))
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          SendEventDisbursement({
            action: 'Failed Privy',
            isSecondApply: getState().loanApply.isSecondApply
          });
          sendException({
            desc: 'Failed Privy',
            fatal: 'true'
          });
          dispatch(setError('privysys_fail'));
          return;
        }

        // Record is already exist in privy
        if (getChannelContextCode(err, 'channel-context') === '103557') {
          dispatch(fetchPrivyRegData(auth, param));
          return;
        }

        if (getChannelContextCode(err, 'channel-context')) {
          SendEventDisbursement({
            action: 'User Failed to Match face on Dukcapil',
            isSecondApply: getState().loanApply.isSecondApply
          });
          sendException({
            desc: 'User Failed to Match face on Dukcapil',
            fatal: 'true'
          });
          if (getChannelContextCode(err, 'channel-context') === '211102') {
            dispatch(setError(Photo_Not_Matched));
            return;
          } else if (
            getChannelContextCode(err, 'channel-context') === '211104'
          ) {
            dispatch(setError(Nik_And_Photo_Not_Match));
            return;
          } else if (
            getChannelContextCode(err, 'channel-context') === '211105'
          ) {
            dispatch(setError(Photo_Not_Detected));
            return;
          } else if (
            getChannelContextCode(err, 'channel-context') === '211106'
          ) {
            dispatch(setError(Nik_Not_Registered));
            return;
          } else {
            SendEventDisbursement({
              action: 'Failed Privy',
              isSecondApply: getState().loanApply.isSecondApply
            });
            sendException({
              desc: 'Failed Privy',
              fatal: 'true'
            });
            dispatch(setError('privysys_fail'));
            return;
          }
        }

        dispatch(setError('system'));
      })
      .finally(() => {
        param?.accept && dispatch(loading(false));
      });
  };
};

export const fetchPrivyTnC = () => {
  return (dispatch, getState) => {
    dispatch(loading(true));
    const url = STANDARD_TEXT + 'DTS';
    const header = {
      headers: {
        Authorization: getState().auth.VTtoken
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        dispatch(setPrivyTnC(res.data.data));
        dispatch(loading(false));
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
          dispatch(loading(false));
        } else {
          dispatch(setError('system'));
          dispatch(loading(false));
        }
      });
  };
};

export const fetchPrivyRegData = (auth, param) => {
  return dispatch => {
    // dispatch(loading(true))
    const url =
      USERS +
      '/' +
      (localStorage.getItem('phonePrincipal') || auth.phoneNo) +
      '/custom/privyregistrations?applicationId=' +
      auth.applicationId;
    const header = {
      headers: {
        Authorization: auth.token
      }
    };

    axios
      .get(url, header)
      .then(res => {
        !param.accept && dispatch(setApplicationStatus('EKYC_COM'));
        dispatch(setPrivyRegData(res.data));
        param.action && param.action();
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const fetchPinangDocument = (auth, param) => {
  return dispatch => {
    // dispatch(loading(true))
    const url =
      USERS +
      '/applications/' +
      auth.applicationId +
      '/custom/doctext/' +
      param.docType;
    const header = {
      headers: {
        Authorization: auth.token
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        const doc = res.data.data;
        if (param.docType === 'DTRM') dispatch(setPinangDoc1(doc));
        if (param.docType === 'DAOF') dispatch(setPinangDoc2(doc));
        if (param.docType === 'DBTL') dispatch(setPinangDoc3(doc));
        // dispatch(loading(false))
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
          // dispatch(loading(false))
        } else {
          dispatch(setError('system'));
          // dispatch(loading(false))
        }
      });
  };
};

export const generatePrivyOTP = (auth, params) => {
  return dispatch => {
    dispatch(loading(true));
    const url =
      USERS + '/custom/applications/' + auth.applicationId + '/generateOtp';
    const header = {
      headers: {
        Authorization: auth.token
        // 'Access-Control-Allow-Origin': '*'
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        dispatch(setPrivyToken(res.data.token));
        dispatch(loading(false));
        params.action && params.action();
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
          dispatch(loading(false));
        } else {
          dispatch(setError('system'));
          dispatch(loading(false));
        }
      });
  };
};

export const verifyPrivyOTP = (auth, params) => {
  return dispatch => {
    dispatch(loading(true));
    const url =
      USERS + '/custom/applications/' + auth.applicationId + '/verifyOTP';
    const header = {
      headers: {
        Authorization: auth.oauthToken
      }
    };
    const body = {
      otp: params.otp,
      token: auth.privyToken
    };

    axiosGolang
      .post(url, body, header)
      .then(() => {
        dispatch(loading(false));
        params.success && params.success();
        params.action && params.action();
      })
      .catch(err => {
        if (err.response && err.response.status === 400) {
          params.failed && params.failed(err.response);
          dispatch(setError('incorrect_otp'));
          dispatch(loading(false));
        } else if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
          dispatch(loading(false));
        } else {
          dispatch(setError('system'));
          dispatch(loading(false));
        }
      });
  };
};

export const retrieveSelfieVerify = auth => {
  return dispatch => {
    dispatch(loading(true));
    const url = ELEMENT_GET_IMAGES + auth.txnId + '&withResult=true';
    const header = {
      headers: {
        CLIENTID: auth.clientId,
        TIMESTAMP: auth.timeStamp,
        HASHTOKEN: auth.hashToken
      }
    };
    axios
      .get(url, header)
      .then(res => {
        const pic1 = 'data:image/jpeg;base64,' + res.data.images[0].data;
        const pic2 = 'data:image/jpeg;base64,' + res.data.images[1].data;
        dispatch(takeSelfieFinish(pic1, pic2, res.data.status));
        dispatch(loading(false));
        // params.action && params.action()
      })
      .catch(err => {
        if (err.response && err.response.status === 403) {
          dispatch(setError('sesi_habis'));
          dispatch(loading(false));
        } else if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
          dispatch(loading(false));
        } else {
          dispatch(setError('system'));
          dispatch(loading(false));
        }
      });
  };
};

export const privySelfieVerification = (auth, param) => {
  return dispatch => {
    const url =
      USERS + '/custom/applications/' + auth.applicationId + '/validatephoto';
    const header = {
      headers: {
        Authorization: auth.token
      }
    };
    const body = {
      token: auth.privyToken,
      liveImage1: {
        fileData: param.fileData1,
        fileName: auth.applicationId + '.jpg'
      },
      liveImage2: {
        fileData: param.fileData2,
        fileName: auth.applicationId + '.jpg'
      }
    };
    axiosGolang
      .post(url, body, header)
      .then(res => {
        dispatch(setPrivyValidRes(res.data));
        dispatch(setApplicationStatus(res.data.data[0].statusAfterDocCreation));
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          param.fail && param.fail();
          return;
        }

        // 101043
        // The functionality does not exist currently. Inform the help desk.
        if (getChannelContextCode(err, 'channel-context') === '101043') {
          dispatch(setError('contact-cs'));
          return;
        }

        // Disbursement Failure
        if (
          getChannelContextCode(err, 'channel-context') === '211094' ||
          getChannelContextCode(err, 'channel-context') === '200606' ||
          getChannelContextCode(err, 'channel-context') === '100126'
        ) {
          dispatch(setError('loanCreateFail'));
          param.fail && param.fail();
          return;
        }

        // Verification isn't match
        if (getChannelContextCode(err, 'channel-context') === '211057') {
          dispatch(countingTakePicture());
          if (param.selfieCount === 3) {
            dispatch(setError('DIG_SIG_FAIL'));
            // dispatch(resetCountingPicture())
            return;
          } else if (param.selfieCount === 7) {
            dispatch(setError('DIG_SIG_FAIL7'));
            dispatch(resetCountingPicture());
            return;
          } else {
            dispatch(setError('PIC_NOT_MATCHED'));
            param.picFailed && param.picFailed();
            return;
          }
        }

        dispatch(setError('system'));
        return;
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const createLoanInstallment = (auth, param) => {
  return (dispatch, getState) => {
    const url =
      USERS + '/custom/applications/' + auth.applicationId + '/createloan';
    const header = {
      headers: {
        Authorization: auth.token,
        'Content-Type': 'application/json'
      }
    };

    param?.loading && dispatch(loading(true));
    axiosGolang
      .put(url, null, header)
      .then(res => {
        dispatch(setCreateLoanRes(res.data.data[0]));
        dispatch(setApplicationStatus(res.data.data[0]?.status));
        if (res.data.data[0].status === 'DISB_IN_PROCESS') {
          param.inProcess && param.inProcess();
          return;
        }
        dispatch(loading(false));
        param.action && param.action();
      })
      .catch(err => {
        SendEventDisbursement({
          action: JSON.stringify(err.response),
          isSecondApply: getState().loanApply.isSecondApply
        });
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        if (
          getChannelContextCode(err, 'channel-context') === null ||
          getChannelContextCode(err, 'channel-context') === '23409' ||
          getChannelContextCode(err, 'channel-context') === '100126' ||
          getChannelContextCode(err, 'channel-context') === '14084'
        ) {
          param.fail && param.fail(err.response);
          return;
        }
        if (getChannelContextCode(err, 'channel-context') === '100127') {
          param.inProcess && param.inProcess();
          dispatch(setApplicationStatus('DISB_IN_PROCESS'));
          return;
        }
        dispatch(setError('system'));
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const createLoanKasbon = (auth, param) => {
  return (dispatch, getState) => {
    const url =
      USERSV2 + '/custom/applications/' + auth.applicationId + '/createloan';

    const header = {
      headers: {
        Authorization: auth.token,
        'Content-Type': 'application/json'
      }
    };

    const bodyKasbon = {
      custCategory: param?.custCategory,
      tenorMonths: param?.tenorMonths
    };

    param?.loading && dispatch(loading(true));
    axiosGolang
      .put(url, bodyKasbon, header)
      .then(res => {
        dispatch(setCreateLoanRes(res.data.data[0]));
        dispatch(setApplicationStatus(res.data.data[0]?.status));
        if (res.data.data[0].status === 'DISB_IN_PROCESS') {
          param.inProcess && param.inProcess();
          return;
        }
        dispatch(loading(false));
        param.action && param.action();
      })
      .catch(err => {
        SendEventDisbursement({
          action: JSON.stringify(err.response),
          isSecondApply: getState().loanApply.isSecondApply
        });
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        if (
          getChannelContextCode(err, 'channel-context') === null ||
          getChannelContextCode(err, 'channel-context') === '23409' ||
          getChannelContextCode(err, 'channel-context') === '100126' ||
          getChannelContextCode(err, 'channel-context') === '14084'
        ) {
          param.fail && param.fail(err.response);
          return;
        }

        dispatch(setError('system'));
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const starsRating = (auth, param) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + '/' + '/custom/applications/' + auth.applicationId;

    const body = {
      applicationDetails: {
        feedback: String(param.feedback),
        remarks: String(param.remaks)
      }
    };

    const header = {
      headers: {
        Authorization: auth.token,
        'Content-Type': 'application/json'
      }
    };

    axiosGolang
      .put(url, body, header)
      .then(() => {
        dispatch(loading(false));
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
          dispatch(loading(false));
        } else {
          dispatch(setError('system'));
          dispatch(loading(false));
        }
      });
  };
};

export const getLoan = (auth, param) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + '/custom/loans';

    const header = {
      headers: {
        Authorization: auth.token,
        'Content-Type': 'application/json'
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        dispatch(setLoans(res.data.data));
        dispatch(loading(false));
        param.action && param.action();
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
          dispatch(loading(false));
        } else {
          dispatch(setError('system'));
          dispatch(loading(false));
        }
      });
  };
};

export const applicationDocuments = (auth, param) => {
  return dispatch => {
    dispatch(loading(true));

    const url = USERS + '/' + 'custom/applications/document-list';
    const header = {
      headers: {
        Authorization: auth.token,
        'Content-Type': 'application/json'
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        dispatch(setApplicationDocument(res.data.data));
        param.action && param.action();
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }
        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          return;
        }
        if (getChannelContextCode(err, 'channel-context') === '200606') {
          dispatch(setError('noDocument'));
          param.action && param.action();
          return;
        }
        dispatch(setError('system'));
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const downloadAppDocument = (auth, param) => {
  return dispatch => {
    dispatch(loading(true));

    const url =
      USERS +
      '/custom/fileattachment/' +
      auth.applicationId +
      '/' +
      param.fileNo;
    const header = {
      headers: {
        Authorization: auth.token,
        'Content-Type': 'application/json'
      },
      responseType: 'blob'
    };
    axiosGolang
      .get(url, header)
      .then(res => {
        downloadPDF(res.data, res.headers['content-disposition']);
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }
        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          return;
        }
        dispatch(setError('system'));
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const getLoanAsync = (auth, param) => {
  return async dispatch => {
    dispatch(loading(true));

    const url = USERS + '/custom/loans';

    const header = {
      headers: {
        Authorization: auth.token,
        'Content-Type': 'application/json'
      }
    };

    try {
      const res = await axiosGolang.get(url, header);

      if (!res.data.data) {
        dispatch(setError('system'));
        return;
      }

      dispatch(setLoans(res.data.data));
      param && param.action();
      dispatch(loading(false));
    } catch (err) {
      if (err.toString().includes('Network Error')) {
        dispatch(setError('connection'));
        dispatch(setIsLogin(false));
        dispatch(loading(false));
      } else {
        dispatch(setError('system'));
        dispatch(setIsLogin(false));
        dispatch(loading(false));
      }
    }
  };
};

export const setTimer = timer => {
  return {
    type: actionTypes.SET_TIMER,
    timer: timer
  };
};

export const startTimer = () => {
  return {
    type: actionTypes.START_TIMER
  };
};

export const decMinutes = () => {
  return {
    type: actionTypes.DEC_MINUTES
  };
};

export const decSeconds = () => {
  return {
    type: actionTypes.DEC_SECONDS
  };
};

export const stopTimer = () => {
  localStorage.removeItem('approvedTime');
  return {
    type: actionTypes.STOP_TIMER
  };
};

let interval = null;
export const countDown = (time, auth) => {
  const { minutes, seconds } = time;
  return dispatch => {
    clearInterval(interval);
    interval = setInterval(() => {
      if (seconds > 0) {
        dispatch(decSeconds());
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
          dispatch(stopTimer());
          dispatch(applicationInquiry(auth));
        } else {
          dispatch(decMinutes());
          dispatch(decSeconds());
        }
      }
      if (seconds === 1 && minutes !== 0) {
        dispatch(applicationInquiry(auth));
      }
    }, 1000);
  };
};

export const setCreditScoreResult = result => {
  return {
    type: actionTypes.SET_CS_RESULT,
    result: result
  };
};

export const setOffering1 = selected => {
  return {
    type: actionTypes.SET_OFFERING_1,
    offering: selected
  };
};

export const setOffering2 = selected => {
  return {
    type: actionTypes.SET_OFFERING_2,
    offering: selected
  };
};

export const setSelectedOffering = selected => {
  return {
    type: actionTypes.SET_SELECTED_OFFERING,
    offering: selected
  };
};

export const setloanAmountSelected = selected => {
  return {
    type: actionTypes.SET_LOAN_AMOUNT_SELECETED,
    amount: selected
  };
};

export const setPrivyTnC = tnc => {
  return {
    type: actionTypes.SET_PRIVY_TNC,
    tnc: tnc
  };
};

export const setPrivyRegData = data => {
  return {
    type: actionTypes.SET_PRIVY_REG_DATA,
    data: data
  };
};

export const setPinangDoc1 = doc => {
  return {
    type: actionTypes.SET_PINANG_DOC_1,
    doc: doc
  };
};

export const setPinangDoc2 = doc => {
  return {
    type: actionTypes.SET_PINANG_DOC_2,
    doc: doc
  };
};

export const setPinangDoc3 = doc => {
  return {
    type: actionTypes.SET_PINANG_DOC_3,
    doc: doc
  };
};

export const setPrivyToken = token => {
  return {
    type: actionTypes.SET_PRIVY_TOKEN,
    token: token
  };
};

export const setPrivyValidRes = response => {
  return {
    type: actionTypes.SET_PRIVY_VALID_RES,
    res: response
  };
};

export const setCreateLoanRes = response => {
  return {
    type: actionTypes.SET_CREATE_LOAN_RES,
    res: response
  };
};

export const takeSelfiePrivy = (pic1, pic2, status) => {
  return {
    type: actionTypes.TAKE_SELFIE_PRIVY,
    pic1: pic1,
    pic2: pic2,
    status: status
  };
};

export const takeSelfieFinish = (pic1, pic2, status) => {
  return {
    type: actionTypes.TAKE_SELFIE_FINISH,
    pic1: pic1,
    pic2: pic2,
    status: status
  };
};

export const takeSelfieElement = (pic1, pic2, status) => {
  return {
    type: actionTypes.TAKE_SELFIE_ELEMENT,
    pic1: pic1,
    pic2: pic2,
    status: status
  };
};

export const countingTakePicture = () => {
  return {
    type: actionTypes.COUNT_TAKE_PICTURE
  };
};

export const resetCountingPicture = () => {
  return {
    type: actionTypes.RESET_TAKE_PICTURE
  };
};

export const setApplicationDocument = documents => {
  return {
    type: actionTypes.SET_APPLICATION_DOC,
    doc: documents.reverse()
  };
};

export const setPassiveLivenessImage = imageData => {
  return {
    type: actionTypes.SET_PASSIVE_LIVENESS_IMAGE,
    image: imageData
  };
};

export const setPassiveLivenessImageVerif = imageData => {
  return {
    type: actionTypes.SET_PASSIVE_LIVENESS_IMAGE_VERIF,
    image: imageData
  };
};

export const setEkycKey = key => {
  return {
    type: actionTypes.SET_EKYC_KEY,
    data: key
  };
};

export const setEkycStatus = status => {
  return {
    type: actionTypes.SET_EKYC_STATUS,
    data: status
  };
};
