import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/Utility';
import { getCookie } from 'helpers/saveToCookies';
import { DEVICE_INFORMATION, USER_LOGIN_DATA } from 'common/constants';
import CryptoJS from 'crypto-js';

const decryptDecodedToken = CryptoJS.AES.decrypt(
  getCookie(USER_LOGIN_DATA),
  process.env.REACT_APP_SECRET_KEY
).toString(CryptoJS.enc.Utf8);

const deviceInformation = getCookie(DEVICE_INFORMATION);

// eslint-disable-next-line quotes
const { clientId, clientSecret, VTtoken, partnerId, subCode, referralCode } =
  JSON.parse(decryptDecodedToken ? decryptDecodedToken : '{}') || '';

const { deviceId, deviceName, deviceType } =
  JSON.parse(deviceInformation ? deviceInformation : '{}') || '';

const initialState = {
  appTnC: '',
  splashScreen: '',
  error: '',
  oauthToken: '',
  oauthExpiredTime: '',
  phoneNo: localStorage.getItem('phoneNo')
    ? localStorage.getItem('phoneNo')
    : '',
  phonePrincipal: localStorage.getItem('phonePrincipal')
    ? localStorage.getItem('phonePrincipal')
    : '',
  newPhoneNo: '',
  isLogin: false,
  isBriEmployee: false,
  registeredPin: '',
  typedPin: '',
  newPin: '',
  homePath: '/',
  userProfile: '',
  clientId: clientId ? clientId : '',
  clientSec: clientSecret ? clientSecret : '',
  VTtoken: VTtoken ? VTtoken : '',
  partnerId: partnerId ? partnerId : '',
  subCode: subCode ? subCode : '',
  referralCode: referralCode ? referralCode : '',
  deviceId: deviceId ? deviceId : '',
  deviceName: deviceName ? deviceName : '',
  deviceType: deviceType ? deviceType : ''
};

const setAppTnC = (state, action) => {
  return updateObject(state, { appTnC: action.appTnC });
};

const setSplashScreen = (state, action) => {
  return updateObject(state, { splashScreen: action.data });
};

const setError = (state, action) => {
  return updateObject(state, { error: action.error });
};

const setOauthToken = (state, action) => {
  return updateObject(state, {
    oauthToken: action.token,
    oauthExpiredTime: action.expiredTime,
    isLogin: true
  });
};

const setPhoneNumber = (state, action) => {
  return updateObject(state, { phoneNo: action.phoneNo });
};

const setNewPhoneNo = (state, action) => {
  return updateObject(state, { newPhoneNo: action.phoneNo });
};

const setUserId = (state, action) => {
  return updateObject(state, { phonePrincipal: action.phonePrincipal });
};

const setRegisteredPin = (state, action) => {
  return updateObject(state, { registeredPin: action.pin });
};

const inputPinLogin = (state, action) => {
  return updateObject(state, {
    typedPin: state.typedPin + action.typedPin
  });
};

const setNewPin = (state, action) => {
  return updateObject(state, { newPin: action.newPin });
};

const delPinLogin = state => {
  return updateObject(state, {
    typedPin: state.typedPin.slice(0, -1)
  });
};

const resetTypedPin = state => updateObject(state, { typedPin: '' });

const setHomePath = (state, action) => {
  return updateObject(state, { homePath: action.path });
};

const setIsLogin = (state, action) => {
  return updateObject(state, { isLogin: action.status });
};

const setUserProfile = (state, action) => {
  return updateObject(state, { userProfile: action.data });
};

const setParameterAccess = (state, action) => {
  return updateObject(state, {
    clientId: action.params.clientId,
    clientSec: action.params.clientSecret,
    VTtoken: action.params.VTtoken,
    partnerId: action.params.partnerId,
    subCode: action.params.subCode || '',
    referralCode: action.params.referralCode || ''
  });
};

const setDeviceInformation = (state, action) => {
  return updateObject(state, {
    deviceId: action.deviceInformation.deviceId,
    deviceName: action.deviceInformation.deviceName,
    deviceType: action.deviceInformation.deviceType
  });
};

const setData = (state, action) => {
  return updateObject(state, { data: action.data });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_APP_TNC:
      return setAppTnC(state, action);
    case actionTypes.SET_SPLASH_SCREEN:
      return setSplashScreen(state, action);
    case actionTypes.SET_ERROR:
      return setError(state, action);
    case actionTypes.SET_OAUTH_TOKEN:
      return setOauthToken(state, action);
    case actionTypes.SET_PHONE_NUMBER:
      return setPhoneNumber(state, action);
    case actionTypes.SET_USER_ID:
      return setUserId(state, action);
    case actionTypes.SET_REG_PIN:
      return setRegisteredPin(state, action);
    case actionTypes.SET_NEW_PIN:
      return setNewPin(state, action);
    case actionTypes.TYPING_PIN:
      return inputPinLogin(state, action);
    case actionTypes.DEL_PIN:
      return delPinLogin(state);
    case actionTypes.RESET_TYPED_PIN:
      return resetTypedPin(state);
    case actionTypes.SET_HOME_PATH:
      return setHomePath(state, action);
    case actionTypes.SET_IS_LOGIN:
      return setIsLogin(state, action);
    case actionTypes.SET_PROFILE_DATA:
      return setUserProfile(state, action);
    case actionTypes.SET_PARAMETER_ACCESS:
      return setParameterAccess(state, action);
    case actionTypes.SET_NEW_PHONENO:
      return setNewPhoneNo(state, action);
    case actionTypes.SET_DATA:
      return setData(state, action);
    case actionTypes.SET_DEVICE_INFORMATION:
      return setDeviceInformation(state, action);
    default:
      return state;
  }
};

export default reducer;
