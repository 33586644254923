import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { getPartnerId } from 'helpers/getPartnerId';
import { getIconBottomNavBar } from 'helpers/getAssetsByPartner';

const BottomNav = () => {
  const useStyles = makeStyles({
    bottomNav: {
      maxWidth: '500px',
      width: '100%',
      height: '50px',
      position: 'fixed',
      bottom: 0,
      boxShadow: '1px -2px 4px rgba(51, 51, 51, 0.05)',
      margin: '0px auto',
      WebkitBoxPack: 'justify',
      alignItems: 'center'
    },
    nav: {
      paddingBottom: '60px'
    }
  });

  const location = useLocation();
  const classes = useStyles();

  const [value, setValue] = React.useState(location.pathname);

  React.useEffect(() => {
    setValue(location.pathname);
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const loan =
    value === '/history' ? (
      <img
        alt="pinjaman"
        src={getIconBottomNavBar(getPartnerId, 'activeLoanIcon')}
      />
    ) : (
      <img alt="pinjaman" src="/images/assets/pinjaman_newidle_grey.svg" />
    );
  const profile =
    value === '/profile' ? (
      <img
        alt="profile"
        src={getIconBottomNavBar(getPartnerId, 'activeProfileIcon')}
      />
    ) : (
      <img alt="profile" src="/images/assets/profile_newidle_grey.svg" />
    );
  const home =
    value === '/home' ? (
      <img
        alt="home"
        src={getIconBottomNavBar(getPartnerId, 'activeHomeIcon')}
      />
    ) : (
      <img alt="home" src="/images/assets/home_newidle_grey.svg" />
    );

  const notification =
    value === '/notification' ? (
      <img
        alt="notification"
        src={getIconBottomNavBar(getPartnerId, 'activeNotificationIcon')}
      />
    ) : (
      <img alt="notification" src="/images/assets/notification_idle.svg" />
    );

  return (
    <nav className={classes.nav}>
      <BottomNavigation
        className={classes.bottomNav}
        onChange={handleChange}
        showLabels
        value={value}
      >
        <BottomNavigationAction
          component={Link}
          icon={home}
          label="Home"
          to="/home"
          value="/home"
        />
        <BottomNavigationAction
          component={Link}
          icon={loan}
          label="Pinjaman"
          to="/history"
          value="/history"
        />
        <BottomNavigationAction
          component={Link}
          icon={notification}
          label="Notifikasi"
          to="/notification"
          value="/notification"
        />
        <BottomNavigationAction
          component={Link}
          icon={profile}
          label="Profil"
          to="/profile"
          value="/profile"
        />
      </BottomNavigation>
    </nav>
  );
};

export default BottomNav;
